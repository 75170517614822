@charset "UTF-8";
* {
  font-family: ff-enzo-web, sans-serif;
  font-size: 15px;
}

a {
  color: #333;
}
a:hover {
  color: #123456;
}

h1 {
  font-size: 2.5em;
  color: #1E9DC7;
  line-height: 1.2;
}
@media (max-width: 500px) {
  h1 {
    font-size: 2em;
  }
}

h2 {
  font-size: 2em;
  color: #1E9DC7;
  line-height: 1.2;
}
@media (max-width: 500px) {
  h2 {
    font-size: 1.7em !important;
    line-height: 1.2;
  }
}

h3 {
  color: white;
  line-height: 1.2;
  font-size: 1.2em;
}
@media (max-width: 500px) {
  h3 {
    font-size: 1.1em;
  }
}

h4 {
  font-size: 1.1em;
}

h5 {
  color: #004F5C;
  font-size: 1.5em;
  font-weight: 300;
}
@media (max-width: 500px) {
  h5 {
    font-size: 1.3em;
  }
}

strong {
  color: #5B5B5B;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
  line-height: 1.5;
  color: #333;
  position: relative;
}

body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 70px;
}
@media (max-width: 1150px) {
  body {
    padding-top: 58px;
  }
}
body.scrolled {
  padding-top: 40px;
}

.boxed-content {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}
body {
  background-color: #FFFFFF;
}

section {
  background: white;
}

a:hover {
  color: #1E9DC7;
}

nav {
  width: 100%;
  background: #fff;
  align-items: center;
  height: 70px;
  z-index: 1000000000000000100;
  position: fixed;
  top: 0;
  left: 0;
  transition: all 300ms ease;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
}
nav #logo {
  transition: all 300ms ease;
  width: 130px;
  height: 130px;
  z-index: 68;
  width: 130px;
  top: -10px;
  left: 50%;
  position: absolute;
  transform: translate(-50%);
}
nav #logo img {
  width: 100%;
  height: auto;
  display: block;
}
nav #burger {
  display: none;
}
nav #menu {
  display: flex;
  align-items: center;
  padding: 0 30px;
  width: 100%;
  margin: 0 auto;
  height: 100%;
  justify-content: space-between;
}
nav #menu .menupunkte {
  display: flex;
  align-items: center;
  height: 100%;
  transition: all 300ms ease;
}
nav #menu .menupunkte a, nav #menu .menupunkte .has-submenu {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 300ms ease;
}
@media (max-width: 1150px) {
  nav #menu .menupunkte a, nav #menu .menupunkte .has-submenu {
    padding: 10px;
  }
}
nav #menu .menupunkte .has-submenu {
  position: relative;
  user-select: none;
  padding: 10px 20px;
}
nav #menu .menupunkte .has-submenu:hover {
  background: #1E9DC7;
  color: white;
}
@media (max-width: 1150px) {
  nav #menu .menupunkte .has-submenu {
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    text-align: center;
    display: block;
  }
}
nav #menu .menupunkte .has-submenu .submenu {
  position: relative;
}
@media (min-width: 1151px) {
  nav #menu .menupunkte .has-submenu .submenu {
    position: absolute;
    top: 100%;
    left: 0;
    width: 200%;
    height: auto;
    background: white;
    display: none;
  }
}
nav #menu .menupunkte .has-submenu .submenu a {
  padding: 10px !important;
}
nav #menu .menupunkte .has-submenu .submenu li:first-of-type {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  margin-top: 10px;
}
nav #menu .menupunkte .has-submenu:hover .submenu {
  display: block;
}
nav #menu .menuItem-right {
  align-self: flex-end;
}
nav #menu a, nav #menu span {
  padding: 10px 20px;
  position: relative;
  cursor: pointer;
  color: black;
}
nav #menu a #submenu, nav #menu span #submenu {
  display: none;
  position: absolute;
  left: 0;
  top: 100%;
}
nav #menu a:hover, nav #menu a.active, nav #menu span:hover, nav #menu span.active {
  background: #1E9DC7;
  color: white;
}
nav.scrolled {
  height: 40px;
}
nav.scrolled #logo {
  width: 100px;
  height: 100px;
  top: -20px;
}

@media (max-width: 1150px) {
  nav {
    height: 58px;
    z-index: 999999;
  }
  nav #menu {
    padding-left: 0;
    padding-right: 0;
    position: absolute;
    opacity: 0;
    transform: translate(110vw);
    transition: all 300ms ease;
    height: calc(105vh - 58px);
    display: block;
    width: 100%;
    right: 0;
    top: 58px;
    position: absolute;
    background: #1E9DC7;
    padding-top: 70px;
    z-index: -10;
  }
  nav #menu a, nav #menu span {
    text-align: right !important;
    color: white;
    display: block;
    padding: 15px 30px;
    width: 100%;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  nav #menu a:hover, nav #menu span:hover {
    background: #74b8d6;
  }
  nav #menu a #submenu, nav #menu span #submenu {
    position: relative;
    left: 0;
    top: 0;
    width: 100vw;
    left: -20px;
    margin-top: 20px;
    marginbottom: 0;
  }
  nav #menu a #submenu a, nav #menu span #submenu a {
    min-width: 100%;
    text-align: right;
    padding: 10px 45px 10px 25px;
  }
  nav #menu a #submenu a:hover, nav #menu span #submenu a:hover {
    left: 0;
    padding-right: 30px;
    background: #2ab2df;
  }
  nav #menu .menupunkte {
    height: auto;
    display: block;
    margin-top: 0 !important;
  }
  nav #menu .menupunkte a {
    height: auto;
  }
  nav #menu.active {
    transform: translate(0);
    opacity: 1;
  }
  nav #burger {
    display: block;
    width: 30px;
    height: 30px;
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    transition: all 300ms ease;
  }
  nav #burger span {
    width: 24px;
    height: 2px;
    background: #1E9DC7;
    display: block;
    border-radius: 4px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: all 300ms ease;
  }
  nav #burger span:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: inherit;
    top: -7px;
    transition: all 300ms ease;
  }
  nav #burger span:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: inherit;
    top: 7px;
    transition: all 300ms ease;
  }
  nav #burger.active span {
    transform: rotate(45deg);
  }
  nav #burger.active span:before {
    width: 0;
    top: 0;
  }
  nav #burger.active span:after {
    transform: rotate(-90deg);
    top: 0;
  }
  nav.scrolled {
    height: 40px;
  }
  nav.scrolled #logo {
    width: 80px;
  }
  nav.scrolled #menu {
    top: 40px;
  }
}
@media (max-width: 750px) {
  nav #logo {
    width: 100px;
    height: 100px;
    top: -20px;
  }
}
#sticky {
  position: fixed;
  right: 0;
  top: 50%;
  z-index: 99999;
  padding: 0;
}
#sticky .sticky {
  padding: 10px 20px 10px 10px;
  background: #fff;
  margin-bottom: 20px;
  text-align: center;
  display: block;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  transition: all 300ms ease;
  right: -10px;
}
#sticky .sticky:hover {
  right: 0px;
}
#sticky .sticky h4 {
  font-weight: 500;
  font-size: 0.825em;
  transition: all 300ms ease;
}
#sticky .sticky img {
  height: 50px;
  transition: all 300ms ease;
}
#sticky.scrolled h4 {
  display: none;
}
#sticky.scrolled img {
  height: 40px;
}
@media (max-width: 600px) {
  #sticky.scrolled {
    background: transparent;
    box-shadow: none;
    justify-content: center;
    padding-bottom: 0;
  }
  #sticky.scrolled .sticky {
    width: 60px;
    margin: 0 2px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  }
  #sticky.scrolled .sticky img {
    height: 30px;
    margin: 0;
  }
}
@media (max-width: 600px) {
  #sticky {
    top: auto;
    bottom: 0;
    width: 100%;
    display: flex;
    padding: 0;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  }
  #sticky .sticky {
    width: 100%;
    margin: 0;
    padding: 10px;
    left: 0;
    right: 0;
    box-shadow: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #sticky .sticky img {
    margin-right: 10px;
  }
  #sticky .sticky:hover {
    background: #eff7f9;
  }
  #sticky .sticky img {
    height: 35px;
  }
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

.centered-content {
  padding: 30px;
  text-align: center;
}

.row {
  padding: 30px;
  display: block;
  max-width: 800px;
  margin: 0 auto;
}

.soft-row {
  display: flex;
  flex-wrap: wrap;
}

.col-1 {
  flex: 1 0 0;
}

.col-2 {
  flex: 2 0 0;
}

.col-3 {
  flex: 3 0 0;
}

.col-4 {
  flex: 4 0 0;
}

.col-5 {
  flex: 5 0 0;
}

.col-6 {
  flex: 6 0 0;
}

hr {
  margin: 20px 0;
}

.btn-gelb {
  background: #FFBB00;
  text-decoration: none;
  color: white;
  padding: 5px 25px;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 1px;
  display: inline-block;
  position: relative;
  bottom: 0px;
  transition: all 300ms ease;
}
.btn-gelb:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
  color: white;
}
@media (max-width: 400px) {
  .btn-gelb {
    font-size: 15px;
  }
}

.error {
  background: #e16f4a;
  text-align: center;
  color: white;
  margin: 10px 0;
  padding: 10px 15px;
  display: block;
}

.lightblue {
  background: #DFEBFC;
}

.fullwidth {
  width: 100%;
}

.padding {
  padding: 30px;
}

.info--box {
  margin: 0 auto 2rem;
  padding: 1rem;
  background: white;
  text-align: center;
  border: 2px solid #1E9DC7;
  width: 100%;
  max-width: 40rem;
}
.info--box * {
  text-align: center;
  display: block;
}

.info--box-blue {
  margin: 0 auto 2rem;
  padding: 2rem;
  background: #d1e7f1;
  text-align: center;
  border: 1px solid #1E9DC7;
  width: 100%;
}
.info--box-blue * {
  text-align: center;
}
.info--box-blue h4 {
  color: #1E9DC7;
  font-size: 1.2rem;
  margin-bottom: 1rem;
  display: block;
}

#toggle {
  width: 100%;
}
#toggle dt {
  padding: 10px 20px;
  cursor: pointer;
  background: #123456;
  color: #ffffff;
  border-radius: 2px;
  position: relative;
}
#toggle dt:after {
  content: "▾";
  position: absolute;
  right: 20px;
  top: 10px;
  font-size: inherit;
  transform: rotate(0deg);
  transition: all 0.3s ease;
}
#toggle dt.active:after {
  transform: rotate(-180deg);
}
#toggle dd {
  padding: 0 20px;
  background: #e6e6e6;
  height: 0;
  overflow: hidden;
  margin-bottom: 10px;
}
#toggle dd.active {
  height: auto;
  padding: 20px;
}

form {
  position: relative;
  overflow: hidden;
  max-width: 600px;
  background: #efefef;
  padding: 20px;
}
form input[type=text],
form input[type=email],
form input[type=tel],
form input[type=range],
form textarea {
  width: 100%;
  margin-bottom: 20px;
  padding: 5px 10px;
  margin-top: 5px;
  outline: none;
  border: none;
}
form input {
  padding: 5px 10px;
  outline: none;
  border: none;
  margin-bottom: 20px;
  margin-top: 5px;
}
form label {
  display: inline-block;
}
form .labelRadio {
  margin-right: 20px;
}
form input[type=checkbox],
form input[type=radio] {
  margin-right: 5px;
}
form #url2, form #url {
  position: absolute;
  left: 2000px;
  opacity: 0;
}

.kontakt #form, .sponsor-werden #form, .newsletterbestellen #form {
  text-align: left;
  align-items: center;
  margin: 50px auto;
  background: transparent;
}
.kontakt #submit, .sponsor-werden #submit, .newsletterbestellen #submit {
  background: #FFBB00;
  color: white;
  padding: 10px 20px;
  font-size: 1.2em;
  cursor: pointer;
  transition: all 200ms ease;
}
.kontakt #submit:hover, .sponsor-werden #submit:hover, .newsletterbestellen #submit:hover {
  background: #f9c963;
}
.kontakt label, .sponsor-werden label, .newsletterbestellen label {
  color: white;
  display: inline-block;
  max-width: 90%;
  margin: 0 0 10px;
}
.kontakt label sup, .sponsor-werden label sup, .newsletterbestellen label sup {
  color: white;
}
.kontakt input[type=checkbox], .sponsor-werden input[type=checkbox], .newsletterbestellen input[type=checkbox] {
  width: 16px;
  height: 16px;
  text-align: left;
  display: inline-block;
  vertical-align: top;
}
.kontakt textarea, .sponsor-werden textarea, .newsletterbestellen textarea {
  height: 200px;
}

#footer {
  width: 100%;
  background: #1E9DC7;
  padding: 0 20px;
}
#footer .footerContent {
  max-width: 1200px;
  align-items: center;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
@media (max-width: 965px) {
  #footer .footerContent {
    display: block !important;
    text-align: center;
    padding: 20px 0;
  }
}
#footer .footerContent a {
  color: white;
  margin-top: 30px;
  padding: 0 15px;
  max-width: 10%;
  order: 2;
}
#footer .footerContent a:hover {
  color: #FFBB00 !important;
}
#footer .footerContent div {
  margin-top: 30px;
  max-width: 40%;
}
@media (max-width: 965px) {
  #footer .footerContent div {
    align-items: center;
    margin: 0 auto;
    max-width: 100%;
  }
}
#footer .footerContent #footerpunkte {
  order: 3;
  margin-top: 20px;
}
#footer .footerContent p {
  color: white;
}
#footer .footerContent span {
  color: white;
}
#footer .footerContent .mobile {
  display: none;
}
@media (max-width: 965px) {
  #footer .footerContent .mobile {
    display: inline-block;
  }
}
@media (max-width: 600px) {
  #footer {
    padding-bottom: 80px !important;
  }
  #footer.scrolled {
    padding-bottom: 55px !important;
  }
}

.header {
  width: 100%;
  margin: 0 auto;
  min-height: 450px;
  max-height: 600px;
  background: #ebf6fb url(../img/clauskleber.jpg) top left 55% no-repeat;
  background-size: cover;
  position: relative;
  height: 60vw;
}
@media (min-width: 751px) {
  .header {
    height: 70vw;
    background-position: top center;
  }
}
.header .stoerer--programm {
  position: absolute;
  right: 1rem;
  bottom: 1rem;
  background: #123456;
  width: 10rem;
  height: 10rem;
  display: flex;
  color: white;
  padding: 1rem;
  text-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transform: rotate(-10deg);
  font-weight: bold;
}
@media (max-width: 750px) {
  .header .stoerer--programm {
    width: 8rem;
    height: 8rem;
  }
}
.header .sternchen {
  position: absolute;
  right: 1rem;
  bottom: 1rem;
  color: white;
  display: block;
  padding: 0.5rem 1rem;
  background: #1E9DC7;
  max-width: 250px;
  text-align: center;
}
.header .sternchen:before {
  content: "";
  position: absolute;
  display: block;
  width: 20px;
  height: 20px;
  top: 0;
  left: 50%;
  background: inherit;
  transform: rotate(45deg) translate(-50%, -25%);
}
@media (min-width: 750px) {
  .header .sternchen:before {
    left: 20%;
  }
}
.header .header-content {
  position: absolute;
  right: 0;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  padding: 20px !important;
  width: 70%;
  height: auto;
  display: block;
  align-items: center;
  flex-wrap: wrap;
}
@media (min-width: 1200px) {
  .header .header-content {
    margin: 0 auto;
  }
}
@media (max-width: 750px) {
  .header .header-content {
    width: 100%;
    top: 0;
    transform: translateY(0);
  }
}
.header .header-content ul {
  margin-top: 2rem;
}
@media (max-width: 750px) {
  .header .header-content ul {
    margin-top: 0.5rem;
  }
}
.header .header-content ul li {
  list-style-type: disc;
  margin-left: 20px;
}
@media (max-width: 750px) {
  .header .header-content ul li {
    margin-left: 10px;
  }
}
.header .header-content * {
  color: white;
}
@media (max-width: 750px) {
  .header .header-content h2 {
    font-size: 2rem;
  }
}
.header .header-content h1 {
  padding: 0 !important;
  margin-left: 0 !important;
  display: inline;
  background: none !important;
  max-width: 17ch;
  transform-origin: left top;
}
@media (max-width: 400px) {
  .header .header-content h1 {
    transform: scale(0.9);
  }
}
.header .header-content h1 span {
  font-size: inherit;
  display: inline-block;
  background: #1E9DC7;
  line-height: 1;
  padding: 1rem;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
}
.header .header-content h1 span:nth-of-type(2) {
  transform: translateX(0rem);
}
@media (min-width: 751px) {
  .header .header-content h1 span:nth-of-type(2) {
    transform: translateX(3rem);
  }
}
.header .header-content h1 span:nth-of-type(3) {
  transform: translate(-1rem, -0.5rem) rotate(2deg);
}
@media (min-width: 751px) {
  .header .header-content h1 span:nth-of-type(3) {
    transform: translate(2rem, -0.5rem) rotate(2deg);
  }
}
.header .header-content h1 span:nth-of-type(1) {
  transform: translate(-1rem, 0.5rem) rotate(-3deg) scale(0.8);
}
@media (min-width: 751px) {
  .header .header-content h1 span:nth-of-type(1) {
    transform: translate(0rem, 1rem) rotate(-3deg) scale(0.8);
  }
}
@media (min-width: 751px) {
  .header .header-content {
    padding-top: 100px;
  }
}
.header .header-content .aktionslogo {
  width: 8rem;
  height: auto;
}
@media (min-width: 751px) {
  .header .header-content .aktionslogo {
    width: 11rem;
  }
}
.header .header-content h1, .header .header-content h3 {
  display: inline-block;
  padding: 8px 16px;
}
.header .header-content h1 {
  background: #1E9DC7;
  color: white;
  margin-left: 15px;
  font-size: 4em;
  z-index: 1;
}
.header .header-content h3 {
  background: white;
  color: #1E9DC7;
  transform: rotate(2deg) translateY(-2px);
  font-size: 2em;
  z-index: 0;
  padding-top: 12px;
}
.header .header-content .btn-gelb {
  padding: 10px 20px;
  text-align: left;
  transform: rotate(-2deg) translateY(-10%);
  margin-left: 40px;
}
.header .header-content .btn-gelb * {
  color: white;
  line-height: 1.2;
  letter-spacing: 0;
}
.header .header-content .btn-gelb p {
  font-size: 0.725em;
  font-weight: 400;
  margin-top: 2px;
}
@media (max-width: 960px) {
  .header .header-content h1 {
    font-size: 3em;
  }
  .header .header-content h3 {
    font-size: 1.5em;
  }
}
@media (max-width: 760px) {
  .header .header-content h1 {
    font-size: 2em;
  }
  .header .header-content h3 {
    font-size: 1.2em;
  }
}
.header img {
  width: 100%;
  margin-bottom: -10px;
  position: relative;
}
.header .stoerer {
  position: absolute;
  right: 5%;
  top: 30%;
  width: 220px;
  height: 220px;
  box-sizing: border-box;
  padding: 10px;
  text-align: center;
  background: #ffbb00;
  border-radius: 50%;
  display: flex;
  font-size: 1.4rem;
  align-items: center;
  color: white;
  font-weight: 700;
  line-height: 1.2;
}
@media (max-width: 950px) {
  .header .stoerer {
    top: 20%;
    width: 180px;
    height: 180px;
    font-size: 1.2rem;
  }
}
@media (max-width: 670px) {
  .header .stoerer {
    top: 20%;
    width: 150px;
    height: 150px;
    font-size: 1rem;
  }
}

.sponsoren2018 {
  margin-top: 10px;
}

#hauptsponsoren2018 {
  background: #1E9DC7;
  padding: 10px;
  display: none;
}

.logosSponsoren {
  background: #D0E7F2;
  box-sizing: border-box;
  margin: 0 auto;
  height: 80px;
  position: relative;
  overflow: hidden;
}
@media (max-width: 750px) {
  .logosSponsoren {
    height: 60px;
  }
}
.logosSponsoren .logos {
  height: 100%;
  position: absolute;
  display: flex;
  left: 0px;
  top: 0;
  width: 3400px;
  padding: 10px 0;
  align-items: center;
  justify-content: space-around;
  box-sizing: border-box;
  animation: logobanner 25s linear infinite;
}
.logosSponsoren .logos a {
  flex: 1;
  height: 100%;
  margin: 0 20px;
  position: relative;
  overflow: hidden;
}
.logosSponsoren .logos a img {
  max-height: 100%;
  max-width: 130px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes logobanner {
  0% {
    left: 0px;
  }
  100% {
    left: -1700px;
  }
}
.live--header {
  margin-top: 10px;
  position: relative;
}
.live--header img {
  width: 100%;
}
.live--header .live--heading-right {
  right: 0;
  text-align: right;
  width: 55%;
}
.live--header .live--heading-left {
  left: 0;
  text-align: left;
  width: 45%;
}
@media (max-width: 650px) {
  .live--header .live--heading-left {
    width: 50%;
  }
}
@media (max-width: 650px) {
  .live--header .live--heading-left {
    margin-top: 20px;
  }
}
.live--header .live--heading-left,
.live--header .live--heading-right {
  bottom: 0;
  position: absolute;
  padding: 20px;
  max-width: 550px;
}
.live--header .live--heading-left h2,
.live--header .live--heading-right h2 {
  color: white;
}
@media (min-width: 1000px) {
  .live--header .live--heading-left,
.live--header .live--heading-right {
    padding: 30px !important;
  }
  .live--header .live--heading-left h2,
.live--header .live--heading-right h2 {
    font-size: 3rem;
  }
}
@media (max-width: 650px) {
  .live--header .live--heading-left h2,
.live--header .live--heading-right h2 {
    font-size: 1.4rem !important;
  }
}
@media (max-width: 650px) {
  .live--header .live--heading-left,
.live--header .live--heading-right {
    position: relative;
    width: 100%;
    padding: 0 20px;
  }
  .live--header .live--heading-left h2,
.live--header .live--heading-right h2 {
    color: #1E9DC7;
    font-size: 1.4rem !important;
    text-align: left;
  }
}
@media (min-width: 471px) and (max-width: 650px) {
  .live--header .live--heading-left,
.live--header .live--heading-right {
    padding: 0 40px;
  }
}

#newsbereich {
  display: flex;
  justify-content: space-between;
  text-align: center;
  background: white;
  flex-wrap: wrap;
}
#newsbereich h1 {
  margin-bottom: 20px;
}
#newsbereich p strong {
  color: black;
  font-size: 18px;
}
@media (max-width: 640px) {
  #newsbereich p strong {
    font-size: 16px;
  }
}
#newsbereich p strong a {
  font: inherit;
}
#newsbereich .referenten1 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 2 0 0;
  padding: 20px;
  flex-wrap: wrap;
}
@media (max-width: 900px) {
  #newsbereich .referenten1 {
    display: block;
  }
}
#newsbereich .referenten1 .gym--bild {
  margin-left: 2rem;
}
@media (max-width: 900px) {
  #newsbereich .referenten1 .gym--bild {
    margin-left: 0;
  }
}
#newsbereich .referenten1 .gym--bild > div {
  width: 100%;
}
#newsbereich .referenten1 .gym--bild img {
  width: 100%;
  height: auto;
  max-width: 200px;
  transform-origin: center;
  transform: rotate(5deg);
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
}
#newsbereich .referenten1 .col-1:last-child {
  padding: 20px;
  min-width: 120px;
}
@media (max-width: 900px) {
  #newsbereich .referenten1 .col-1:last-child {
    padding-top: 0;
  }
}
@media (max-width: 470px) {
  #newsbereich .referenten1 .col-1:last-child {
    padding: 0;
  }
}
#newsbereich .referenten1 .col-1:last-child img {
  max-width: 240px;
  width: 100%;
}
@media (max-width: 450px) {
  #newsbereich .referenten1 .col-1:last-child img {
    max-width: 150px;
  }
}
#newsbereich .referenten1 .col-1:first-child {
  padding: 20px;
  text-align: left !important;
  justify-content: flex-start;
}
@media (max-width: 470px) {
  #newsbereich .referenten1 .col-1:first-child {
    padding: 0;
  }
}
#newsbereich .referenten1 .col-1:first-child h2, #newsbereich .referenten1 .col-1:first-child p {
  margin-bottom: 20px;
}
#newsbereich .referenten1 .col-1:first-child h5 {
  margin-top: 30px;
}
#newsbereich .referenten1 .info--box {
  background: #1E9DC7;
  color: white;
}
#newsbereich .referenten1 .info--box li {
  text-align: left;
  color: white;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 10px;
}
@media (max-width: 640px) {
  #newsbereich .referenten1 .info--box li {
    font-size: 16px;
  }
}
#newsbereich .newsletter {
  flex: 1 0 0;
  background: #dcf3fe;
  min-width: 300px;
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 768px) {
  #newsbereich .newsletter {
    min-width: 100%;
  }
}
#newsbereich .newsletter h5 {
  margin-top: 30px;
}
#newsbereich .newsletter img {
  margin: 20px 0;
  max-width: 200px;
  height: auto;
}
#newsbereich #referentenImg {
  display: flex;
  text-align: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
  justify-content: center;
}
#newsbereich #referentenImg .imageContainer {
  flex-wrap: wrap;
  width: 90px;
  height: 90px;
  min-width: 90px;
  margin: 20px 30px;
}
#newsbereich #referentenImg .imageContainer img {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  margin: 0;
  overflow: hidden;
  border-radius: 50%;
}
#newsbereich #referentenImg .refName {
  font-size: 0.8em;
  margin: 10px 0;
}

.soft-row .col-1 {
  min-width: 300px;
}
.soft-row .col-1 .content {
  padding: 2em;
}
.soft-row .col-1 .content h3 {
  color: #1E9DC7;
  font-size: 2em;
}
@media (max-width: 900px) {
  .soft-row .col-1 .content {
    padding: 1em;
  }
  .soft-row .col-1 .content h3 {
    font-size: 1.4em;
  }
}
.soft-row .col-1 video {
  max-width: 100%;
}

#termin {
  min-width: 300px;
  flex: 1 0 0;
  margin: 0 auto;
  text-align: center;
  background: url(../img/berlin.jpeg) no-repeat center;
  background-attachment: fixed;
  padding: 30px;
  box-sizing: border-box;
  position: relative;
  z-index: 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media (max-width: 655px) {
  #termin {
    min-height: 300px;
  }
}
#termin:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #FFAF30;
  opacity: 0.8;
  z-index: 0;
  pointer-events: none;
}
#termin .h2-anmeldung {
  color: #FFFFFF;
  font-size: 3em;
  line-height: 1;
  letter-spacing: 2px;
  margin: 10px 0;
  font-weight: 800;
  text-transform: uppercase;
}
@media (max-width: 750px) {
  #termin .h2-anmeldung {
    font-size: 2.5em;
  }
}
@media (max-width: 655px) {
  #termin .h2-anmeldung {
    font-size: 2em;
  }
}
#termin .h3-anmeldung {
  color: #FFFFFF;
  font-size: 2em;
  letter-spacing: 2px;
  line-height: 1;
  margin: 10px 0;
  font-weight: 300;
  text-transform: uppercase;
}
@media (max-width: 750px) {
  #termin .h3-anmeldung {
    font-size: 2em;
  }
}
@media (max-width: 655px) {
  #termin .h3-anmeldung {
    font-size: 1em;
  }
}

#pressemitteilungen {
  background: #d1e7f1;
}
#pressemitteilungen .soft-row {
  display: flex;
  padding: 40px;
  flex-wrap: wrap;
  justify-content: center;
}
#pressemitteilungen .soft-row h2 {
  font-size: 3em;
}
@media (max-width: 655px) {
  #pressemitteilungen .soft-row h2 {
    font-size: 2em;
    line-height: 1.4;
    margin-bottom: 20px;
  }
}
@media (max-width: 700px) {
  #pressemitteilungen .soft-row {
    display: block;
  }
}
#pressemitteilungen .col-1 {
  display: block;
  flex-wrap: wrap;
  flex: 1 0 0;
  max-width: 200px;
  padding-right: 20px;
  margin-right: 30px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
}
#pressemitteilungen .col-1 img {
  height: auto;
  margin: 0 10px 10px 0;
}
@media (max-width: 700px) {
  #pressemitteilungen .col-1 {
    flex-direction: row;
    display: flex;
    max-width: none;
    margin: 0;
    padding-right: 0;
  }
  #pressemitteilungen .col-1 img {
    flex: 1 0 28%;
    min-width: 40px;
  }
}
#pressemitteilungen .col-3 {
  flex: 3 0 0;
}
@media (max-width: 400px) {
  #pressemitteilungen .col-3 {
    min-width: 250px;
  }
}

.impressionen {
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  position: relative;
}
@media (max-width: 655px) {
  .impressionen {
    grid-template-columns: repeat(2, 1fr);
  }
}
.impressionen .gridItem {
  min-height: 200px;
  max-height: 300px;
  height: 20vw;
  background-size: cover;
  background-position: center;
  display: block;
}
.impressionen #img1 {
  background-image: url(../img/joey-kelly_2x.jpg);
}
.impressionen #img2 {
  background-image: url(../img/SCHILKE_2021110816495000.jpg);
  background-position: top;
}
@media (max-width: 655px) {
  .impressionen #img2 {
    background-position: 92%;
  }
}
.impressionen #img3 {
  background-image: url(../img/reinhold-messner.jpg);
}
.impressionen #img4 {
  background-image: url(../img/hamaiel003_12.jpg);
}
.impressionen #img5 {
  background-image: url(../img/20ec2260.jpg);
}
@media (max-width: 655px) {
  .impressionen #img5 {
    display: none;
  }
}
.impressionen #img6 {
  background-image: url(../img/tf2011_324_gauck_weiler_kastalio_071111_2x.jpg);
}
@media (max-width: 655px) {
  .impressionen #img6 {
    display: none;
  }
}
.impressionen .btn-blau {
  background: #1E9BC7;
  text-decoration: none;
  color: white;
  color: #FFFFFF;
  font-weight: 600;
  transition: all 300ms ease;
}
.impressionen .btn-blau:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
}
.impressionen .btn-blau.impressionen {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: block;
  max-width: 600px;
  margin: 0;
  padding: 10px;
  line-height: 30px;
  width: 80%;
  text-align: center;
  font-size: 2rem;
  cursor: pointer;
}

.liste {
  margin: 0;
}
.liste li {
  list-style-type: disc;
  margin-left: 20px;
  margin-bottom: 10px;
}

.greeting {
  position: relative;
  background: #1E9DC7;
  padding: 2rem;
}
.greeting .greeting--content {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}
.greeting * {
  color: white;
}
.greeting h3 {
  color: white;
  margin-bottom: 1rem;
  font-size: 24px;
}
.greeting a {
  color: #FFBB00;
  text-decoration: underline;
  font-size: inherit;
}
.greeting .noticket {
  background: white;
  padding: 1rem;
  margin-top: 2rem;
}
.greeting .noticket h3 {
  color: #1E9DC7;
}
.greeting .noticket * {
  color: black;
}
.greeting .noticket a {
  color: #1E9DC7;
}

.allesponsoren {
  background: #d1e7f1;
  text-align: center;
}
.allesponsoren .hauptsponsoren2018 {
  background: white;
}
.allesponsoren h3 {
  color: #1E9DC7;
  font-size: 2em;
  margin: 30px;
}
.allesponsoren h2 {
  font-size: 3em;
  padding: 30px 12px;
  margin-bottom: 30px;
}
@media (max-width: 500px) {
  .allesponsoren h2 {
    font-size: 2.5em !important;
  }
}
.allesponsoren h2 span {
  font-size: 0.7em;
}

.hauptsponsoren {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 20px;
}
@media (max-width: 775px) {
  .hauptsponsoren {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 610px) {
  .hauptsponsoren {
    grid-template-columns: repeat(2, 1fr);
  }
}
.hauptsponsoren .s2 {
  display: flex;
  height: 70px;
}
.hauptsponsoren .s2 a {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.hauptsponsoren .s2 img {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-height: 60px;
}
.sponsoren {
  padding: 40px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}
@media (max-width: 980px) {
  .sponsoren {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (max-width: 650px) {
  .sponsoren {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 520px) {
  .sponsoren {
    grid-template-columns: repeat(2, 1fr);
  }
}
.sponsoren .sponsor-single {
  flex: 1 0 20%;
  padding: 5px;
  min-width: 120px;
}
.sponsoren .sponsor-single a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.sponsoren .sponsor-single a img {
  max-width: 100%;
  max-height: 80px;
}
.sponsoren .s1 {
  justify-content: space-between;
  display: flex;
  max-width: 200px;
}
@media (max-width: 460px) {
  .sponsoren .s1 {
    max-width: 150px;
  }
}
.sponsoren .s1 img {
  width: 80%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.sponsorenHeader {
  background: url(../img/sj_bg.jpg) no-repeat;
  background-size: cover;
  width: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap-reverse !important;
}
.sponsorenHeader div {
  margin: auto;
  max-width: 500px;
}
@media (max-width: 1063px) {
  .sponsorenHeader div {
    padding-top: 50px;
  }
}
@media (max-width: 600px) {
  .sponsorenHeader div {
    padding: 20px;
  }
}
.sponsorenHeader div h1 {
  color: white;
  line-height: 1;
}
@media (max-width: 560px) {
  .sponsorenHeader div h1 {
    font-size: 1.7em;
  }
}
.sponsorenHeader div span {
  color: #1E9DC7;
  font-size: 2em;
}
@media (max-width: 1063px) {
  .sponsorenHeader div span {
    font-size: 1.5em;
  }
}
.sponsorenHeader div h4 {
  color: white;
}
@media (max-width: 560px) {
  .sponsorenHeader div h4 {
    font-size: 1em;
  }
}
.sponsorenHeader img {
  width: 80%;
  height: 100%;
  max-width: 550px;
  left: 0;
  bottom: 0;
  margin-top: 20px;
}
@media (max-width: 1063px) {
  .sponsorenHeader img {
    max-width: 350px;
  }
}
@media (max-width: 500px) {
  .sponsorenHeader img {
    width: 50%;
  }
}

.datenschutz {
  padding: 100px;
  height: 1050px;
}
@media (max-width: 800px) {
  .datenschutz {
    padding: 50px 50px 50px;
  }
}
.datenschutz a {
  color: #1E9DC7;
}
.datenschutz a:hover {
  color: black;
}

.impressum {
  padding: 100px;
}
@media (max-width: 800px) {
  .impressum {
    padding: 100px 50px 50px;
  }
}

.referenten {
  background: white;
  min-height: 95vh;
}
.referenten .programm {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 100px 2rem 100px;
}
.referenten .boxed--content {
  text-align: center;
}
.referenten .boxed--content .programm--content {
  min-width: 200px;
}
.referenten .boxed--content .programm--content h4 {
  text-align: left;
  margin-top: 2rem;
}
.referenten .boxed--content .programm--referent {
  margin: 20px auto;
}
.referenten .boxed--content .programm--referent img {
  margin: 0 !important;
}
@media (min-width: 640px) {
  .referenten .boxed--content .programm--referent {
    margin: 20px;
  }
}
@media (max-width: 640px) {
  .referenten .boxed--content {
    padding: 4rem 1rem;
  }
  .referenten .boxed--content .referentenContent h3 {
    font-size: 1.4rem !important;
  }
}
.referenten .boxed--content img {
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
}
.referenten .boxed--content h2 {
  margin-bottom: 20px;
}
.referenten .boxed--content h1 {
  text-align: center;
}
@media (max-width: 654px) {
  .referenten .boxed--content h1 {
    text-align: left;
  }
}
.referenten .boxed--content p {
  text-align: left;
}
@media (max-width: 654px) {
  .referenten .boxed--content p {
    text-align: left;
  }
}
.referenten .boxed--content h4 {
  text-align: center;
  margin-top: 50px;
}
.referenten .boxed--content h4:hover {
  color: #1E9DC7;
}
.referenten .boxed--content .referenten {
  margin-top: 70px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.referenten .boxed--content .referenten p, .referenten .boxed--content .referenten h2 {
  text-align: right;
}
@media (max-width: 954px) {
  .referenten .boxed--content .referenten p, .referenten .boxed--content .referenten h2 {
    text-align: left;
  }
}
.referenten .boxed--content .referenten .links p, .referenten .boxed--content .referenten .links h2 {
  text-align: left !important;
}
@media (max-width: 954px) {
  .referenten .boxed--content .referenten .links p, .referenten .boxed--content .referenten .links h2 {
    text-align: left !important;
  }
}
.referenten .boxed--content .referenten span {
  font-style: italic;
}
.referenten .boxed--content .referenten .referentenText {
  flex: 4 0 0;
  min-width: 600px;
}
@media (max-width: 680px) {
  .referenten .boxed--content .referenten .referentenText {
    min-width: 400px;
  }
}
@media (max-width: 580px) {
  .referenten .boxed--content .referenten .referentenText {
    min-width: 300px;
  }
}
@media (max-width: 480px) {
  .referenten .boxed--content .referenten .referentenText {
    min-width: 200px;
  }
}
.referenten .boxed--content .referenten .referentenBild {
  margin: 20px !important;
  width: 150px;
  text-align: center;
}
.referenten .boxed--content .referenten .referentenBild img {
  width: 150px;
  border-radius: 50%;
}
.referenten .boxed--content .keynote {
  width: 150px !important;
  height: 150px !important;
  min-width: 200px !important;
  position: relative;
  margin: 0 auto 20px;
  align-items: center;
  z-index: 0;
}
.referenten .boxed--content .keynote .referentenBild {
  width: 100%;
  height: 100%;
  margin-left: 0 !important;
}
.referenten .boxed--content .keynote:before {
  content: "";
  background: #df8e02;
  width: 180px;
  height: 30px;
  bottom: -5px;
  left: 50%;
  transform: translate(-50%);
  position: absolute;
}
.referenten .boxed--content .keynote:after {
  content: "Keynote-Speaker";
  background: #FFBB00;
  position: absolute;
  left: 50%;
  bottom: -20px;
  padding: 5px 10px;
  color: white;
  transform: translate(-50%);
  width: 151px;
  text-align: center;
  box-sizing: border-box;
  border-radius: 0 0 4px 4px;
}
.referenten .boxed--content .rechts {
  flex-wrap: wrap-reverse !important;
}
.referenten .boxed--content .referentenWrapper {
  text-align: left;
  padding: 0 2rem;
}
.referenten .boxed--content .referentenWrapper h3 {
  color: #1E9BC7;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px !important;
}
.referenten .boxed--content .referentenWrapper em {
  margin-bottom: 20px !important;
  font-style: italic;
  display: block;
}
.referenten .boxed--content .referentenWrapper strong {
  font-weight: 700;
  display: block;
}
.referenten .boxed--content .referentenWrapper p {
  color: #5B5B5B;
  font-size: 1rem;
}
.referenten .boxed--content .referentenWrapper .soft-row {
  align-items: center;
  display: flex;
  padding: 0;
  margin: 80px 0;
}
.referenten .boxed--content .referentenWrapper .referentenContent {
  flex: 1 0 0;
}
.referenten .boxed--content .referentenWrapper .soft-row:nth-child(odd) .imageContainer {
  order: 2;
  margin-right: 0;
  margin-left: 20px;
}
.referenten .boxed--content .referentenWrapper .soft-row:nth-child(odd) .referentenContent,
.referenten .boxed--content .referentenWrapper .soft-row:nth-child(odd) h3,
.referenten .boxed--content .referentenWrapper .soft-row:nth-child(odd) p,
.referenten .boxed--content .referentenWrapper .soft-row:nth-child(odd) em {
  text-align: right !important;
}
.referenten .boxed--content .referentenWrapper .imageContainer {
  width: 150px;
  height: 150px;
  min-width: 150px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  margin-right: 20px;
}
.referenten .boxed--content .referentenWrapper .imageContainer img {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.referenten .boxed--content .referentenWrapper h3 {
  margin: 0;
}
@media (max-width: 900px) {
  .referenten .boxed--content .referentenWrapper .soft-row {
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .referenten .boxed--content .referentenWrapper .soft-row .imageContainer,
.referenten .boxed--content .referentenWrapper .soft-row .referentenContent,
.referenten .boxed--content .referentenWrapper .soft-row .referentenContent h3,
.referenten .boxed--content .referentenWrapper .soft-row .referentenContent p,
.referenten .boxed--content .referentenWrapper .soft-row .referentenContent strong,
.referenten .boxed--content .referentenWrapper .soft-row .referentenContent em {
    text-align: center !important;
    order: 1 !important;
  }
  .referenten .boxed--content .referentenWrapper .imageContainer {
    margin-bottom: 20px;
  }
}
.referenten .boxed--content .referentenWrapper .keynote {
  order: 1 !important;
  margin-bottom: 40px;
  margin-left: 0 !important;
  width: 200px !important;
  height: 200px !important;
  min-width: 200px !important;
  position: relative;
  order: 2;
  margin-left: 20px;
  z-index: 0;
}
.referenten .boxed--content .referentenWrapper .keynote .imageContainer {
  width: 100%;
  height: 100%;
  margin-left: 0 !important;
}
.referenten .boxed--content .referentenWrapper .keynote:after {
  content: "Keynote-Speaker";
  background: #FFBB00;
  position: absolute;
  left: 50%;
  bottom: -20px;
  padding: 10px 20px;
  color: white;
  transform: translate(-50%);
  width: 150px;
  text-align: center;
  box-sizing: border-box;
  border-radius: 0 0 4px 4px;
}
.referenten .boxed--content .referentenWrapper .keynote:before {
  content: "";
  background: #df8e02;
  width: 180px;
  height: 30px;
  bottom: -5px;
  left: 50%;
  transform: translate(-50%);
  z-index: -1;
  position: absolute;
}

.program {
  background: white;
  padding: 2rem;
  text-align: left;
}
.program__item {
  display: flex;
  flex-wrap: wrap;
  max-width: 800px;
  width: 100%;
  margin: 2rem auto;
  padding-bottom: 2rem;
  align-items: flex-start;
  border-bottom: 1px solid #d1e7f1;
}
.program__item-time {
  color: #FFBB00;
  font-weight: bold;
  width: 100px;
  margin-right: 1rem;
  border-right: 2px solid #FFBB00;
  margin-bottom: 1rem;
}
.program__item-content {
  flex: 2 0 70%;
  text-align: left;
}
.program__item-content h3, .program__item-content p, .program__item-content em, .program__item-content a:not(.program__item-highlight) {
  color: #1E9DC7;
  font-size: 1rem !important;
}
.program__item-content h3, .program__item-content p, .program__item-content em {
  display: block;
}
.program__item-content p, .program__item-content em {
  margin-top: 0.5rem;
}
.program__item-highlight {
  display: inline-block;
  width: auto;
  font-weight: bold;
  color: white;
  background: #FFBB00;
  padding: 0.3rem 0.8rem;
  margin-top: 1rem;
  transition: all 300ms ease;
}
.program__item-highlight:hover {
  color: white;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.15);
}

#vorabend {
  background: none;
  padding: 3rem 0;
  text-align: center;
}
#vorabend h3, #vorabend h2 {
  max-width: 800px;
  margin: 0 auto;
}
#vorabend h3 {
  color: #004875;
  margin-bottom: 1rem;
  display: block;
}
#vorabend .soft-row {
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin: 3rem auto;
  max-width: 1000px;
}
#vorabend .soft-row .col-1 p, #vorabend .soft-row .col-1 strong {
  text-align: center;
}
#vorabend #anfahrt__solar {
  width: 100%;
  max-width: 400px;
  margin-top: 3rem;
}

.iframe--wrapper {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
}
.iframe--wrapper iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.trendForumbg .trendForum {
  text-align: center;
}
.trendForumbg .trendForum .headerTrendforum {
  background: url(../img/sj_bg.jpg) no-repeat;
  background-size: cover;
  width: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap-reverse;
}
.trendForumbg .trendForum .headerTrendforum .headline {
  align-items: center;
  margin: auto;
  min-width: 200px;
}
.trendForumbg .trendForum .headerTrendforum .headline h1 {
  text-align: left;
  font-size: 5em;
  line-height: 1;
}
.trendForumbg .trendForum .headerTrendforum .headline h2 {
  color: white;
  font-size: 3em;
}
@media (max-width: 1200px) {
  .trendForumbg .trendForum .headerTrendforum .headline {
    margin-top: 100px;
  }
  .trendForumbg .trendForum .headerTrendforum .headline h1 {
    line-height: 1em;
    font-size: 4em;
  }
}
@media (max-width: 800px) {
  .trendForumbg .trendForum .headerTrendforum .headline h1 {
    font-size: 3em;
  }
  .trendForumbg .trendForum .headerTrendforum .headline h2 {
    font-size: 2em;
  }
}
@media (max-width: 750px) {
  .trendForumbg .trendForum .headerTrendforum .headline {
    margin-top: 50px;
  }
}
@media (max-width: 400px) {
  .trendForumbg .trendForum .headerTrendforum .headline h1 {
    font-size: 2.5em;
  }
}
.trendForumbg .trendForum .headerTrendforum img {
  width: 100%;
  height: 100%;
  max-width: 650px;
  left: 0;
  bottom: 0;
  margin-top: 20px;
}
@media (max-width: 1200px) {
  .trendForumbg .trendForum .headerTrendforum img {
    max-width: 350px;
  }
}
.trendForumbg .trendForum .zukunft {
  background: #d1e7f1;
}
.trendForumbg .trendForum .zukunft h2 {
  padding-top: 20px;
}
.trendForumbg .trendForum .zukunft p {
  padding: 20px 20px 0px;
}
.trendForumbg .trendForum .visionaere {
  margin: 30px 0 50px;
}
.trendForumbg .trendForum .visionaere h2 {
  padding-top: 20px;
}
.trendForumbg .trendForum .visionaere .row2 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 30px;
}
.trendForumbg .trendForum .visionaere .row2 .soft-row {
  margin: 5px;
  width: 23.75%;
  min-width: 250px;
}
@media (max-width: 1054px) {
  .trendForumbg .trendForum .visionaere .row2 .soft-row {
    width: 40%;
  }
}
@media (max-width: 534px) {
  .trendForumbg .trendForum .visionaere .row2 .soft-row {
    width: 100%;
  }
}
.trendForumbg .trendForum .visionaere .row2 .teil1 {
  min-height: 175px;
  display: flex;
  padding: 10px;
  align-items: center;
}
@media (max-width: 534px) {
  .trendForumbg .trendForum .visionaere .row2 .teil1 {
    padding: 15px;
    min-height: 120px;
  }
}
.trendForumbg .trendForum .visionaere .row2 p {
  color: white;
  font-size: 1.3em;
}
.trendForumbg .trendForum .visionaere .row2 p strong {
  color: white;
  font-size: 1em;
}
.trendForumbg .trendForum .visionaere .row2 .einsO {
  background: #3B5998;
}
.trendForumbg .trendForum .visionaere .row2 .zweiO {
  background: #CC0000;
}
.trendForumbg .trendForum .visionaere .row2 .dreiO {
  background: #001E78;
}
.trendForumbg .trendForum .visionaere .row2 .vierO {
  background: #DD4B39;
}
.trendForumbg .trendForum .visionaere .row2 .teil2 {
  display: flex;
  align-items: center;
  background: #d1e7f1;
  width: 100%;
  padding: 10px;
  min-height: 95px;
  justify-content: center;
}
.trendForumbg .trendForum .visionaere .row2 .einsU p {
  color: #3B5998;
}
.trendForumbg .trendForum .visionaere .row2 .zweiU p {
  color: #CC0000;
}
.trendForumbg .trendForum .visionaere .row2 .dreiU p {
  color: #001E78;
}
.trendForumbg .trendForum .visionaere .row2 .vierU p {
  color: #DD4B39;
}
.trendForumbg .trendForum #ideen {
  background: #d1e7f1;
}
.trendForumbg .trendForum #ideen h2 {
  padding-top: 20px;
}
.trendForumbg .trendForum #ideen p {
  padding: 0 20px;
}
.trendForumbg .trendForum #ideen .row3 {
  display: flex;
  justify-content: center;
  margin: 0 10px;
  flex-wrap: wrap;
}
.trendForumbg .trendForum #ideen .row3 p {
  color: #1E9DC7;
  margin-bottom: 10px;
  font-size: 1.5em;
}
.trendForumbg .trendForum #ideen .row3 p strong {
  color: #1E9DC7;
  font-size: 1.2em;
}
.trendForumbg .trendForum #ideen .row3 a {
  justify-content: space-between;
}
.trendForumbg .trendForum #ideen .row3 .anmelden {
  margin-bottom: 20px;
  padding: 5px;
}
.trendForumbg .trendForum #ideen .row3 .newsletter {
  padding: 5px;
}

.spectaris .spectarisContent {
  padding-top: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between !important;
}
.spectaris .spectarisContent .verbandsstruktur {
  flex: 2 0 0;
  margin: 50px 50px 0 100px;
  min-width: 300px;
}
@media (max-width: 914px) {
  .spectaris .spectarisContent .verbandsstruktur {
    margin: 50px 20px 0 20px;
  }
}
@media (max-width: 400px) {
  .spectaris .spectarisContent .verbandsstruktur {
    min-width: 200px;
  }
}
.spectaris .spectarisContent .verbandsstruktur .verbandsstrukturImg {
  margin: 50px 0 50px 0;
}
.spectaris .spectarisContent .downloads {
  flex: 1 0 0;
  margin: 50px 100px 0 50px;
}
@media (max-width: 914px) {
  .spectaris .spectarisContent .downloads {
    margin: 50px 0 0;
  }
}
.spectaris .spectarisContent .downloads .links {
  background: #FFBB00;
  padding: 20px 20px 20px 20px;
  min-width: 300px;
}
.spectaris .spectarisContent .downloads .links h2 {
  color: white;
}
.spectaris .spectarisContent .downloads .links a {
  color: white;
}
.spectaris .spectarisContent .downloads .links a:hover {
  color: black;
}
.spectaris .spectarisContent .downloads img {
  margin: 0 10px 0 10px;
}
.spectaris .spectarisContent .downloads .spectarisKontakt {
  padding-top: 20px;
  background: #f2f2f2;
  padding: 20px 20px 20px 20px;
  min-width: 300px;
}

.presse {
  margin-top: 70px;
}
.presse h1 {
  text-align: center;
  margin-bottom: 20px;
}
.presse #newswrapper .jahr {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  border-bottom: 1px solid black;
  margin: 0 30px 50px;
}
@media (max-width: 500px) {
  .presse #newswrapper .jahr {
    margin: 0 10px 50px;
  }
}
.presse #newswrapper h3 {
  background: #1E9DC7;
  max-width: 250px;
  padding: 10px;
  text-align: center;
  font-size: 1.5rem;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.4);
}
.presse #newswrapper h2 {
  font-size: 1em !important;
  margin-bottom: 10px;
  color: #333;
  text-align: left;
}
.presse #newswrapper .beitrag {
  text-align: center;
  margin: 30px;
  max-width: 300px;
}
@media (max-width: 500px) {
  .presse #newswrapper .beitrag {
    margin: 30px 10px;
  }
}
.presse #newswrapper .beitrag .image {
  max-width: 300px;
  max-height: 400px;
  text-align: center;
  margin: 20px auto;
}
.presse #newswrapper .beitrag .image img {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  height: 400px;
}
.presse #newswrapper .download-btn {
  margin-top: 10px;
  padding: 10px 10px;
  background: #FFAF30;
  color: white;
  transition: all 300ms ease;
  display: inline-block;
  position: relative;
  text-decoration: none;
  font-size: 0.9em;
  width: 100%;
  display: flex;
  align-items: center;
  font-weight: 800;
}
.presse #newswrapper .download-btn i {
  font-size: 1rem;
  color: white;
  margin-right: 10px;
  border-radius: 50px;
  border: 2px solid white;
  width: 30px;
  height: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.presse #newswrapper .download-btn:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
}

.xjahre {
  text-align: center;
}
@media (max-width: 900px) {
  .xjahre {
    padding: 70px;
  }
}
@media (max-width: 700px) {
  .xjahre {
    padding: 50px;
  }
}
@media (max-width: 500px) {
  .xjahre {
    padding: 20px;
  }
}
.xjahre #imgSlider img {
  max-width: 1000px;
  width: 100%;
}
.xjahre #tf-logos .logoneu {
  padding: 7px;
}
.xjahre #tf-logos img {
  width: 100px;
  margin: auto;
  cursor: pointer;
}
.xjahre p {
  margin-bottom: 100px;
}
.xjahre #highlights {
  margin-bottom: 50px;
}
.xjahre #highlights .logoneu {
  padding: 7px;
}
.xjahre #highlights img {
  max-width: 1000px;
  width: 100%;
}
.xjahre h1 {
  margin-top: 50px;
}
@media (max-width: 900px) {
  .xjahre h1 {
    margin-top: 0px;
  }
}
.xjahre p {
  margin-bottom: 50px;
}
.xjahre .videoContent {
  max-width: 1200px;
  margin: 0 auto 50px;
}
.xjahre .videoContent .iframeWrapper {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
}
.xjahre .videoContent .iframeWrapper iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.xjahre .thumbContainer {
  background: #DAF2FE;
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}
.xjahre .thumbContainer #thumbs {
  padding: 20px 10px;
  box-sizing: border-box;
  margin: 0 auto;
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.xjahre .thumbContainer #thumbs .thumb {
  width: 60px;
  height: 45px;
  background: red;
  margin: 5px !important;
  display: inline-block;
  cursor: pointer;
  line-height: 1;
  box-sizing: border-box;
}
.xjahre #mainImage {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  max-height: 600px;
  min-height: 250px;
  height: 50vw;
  background: #1E9DC7;
  overflow: hidden;
  position: relative;
  margin-top: 20px;
}
.xjahre #mainImage .container {
  display: block;
  flex-wrap: nowrap;
  width: 100%;
  height: 100%;
}
.xjahre #mainImage .container .image {
  flex: 1 0 0;
  width: 100%;
  height: 100%;
  padding-top: 230px;
  position: absolute;
  left: 0;
  top: 0;
  transition: opacity 1500ms ease;
  opacity: 0;
}
.xjahre #mainImage .container .image.active {
  opacity: 1;
}
.xjahre #mainImage #prev {
  position: absolute;
  z-index: 10;
  left: calc(50% - 60px);
  bottom: 0px;
  background: #fff;
  padding: 10px;
  cursor: pointer;
  width: 50px;
  height: 50px;
  background: white url(../img/highlights/prev.svg) center no-repeat;
  background-size: 12px;
  padding: 10px;
  transform: translate(-50%);
}
.xjahre #mainImage #next {
  position: absolute;
  cursor: pointer;
  z-index: 10;
  left: calc(50% + 60px);
  bottom: 0px;
  background: #fff;
  padding: 10px;
  width: 50px;
  height: 50px;
  background: white url(../img/highlights/next.svg) center no-repeat;
  background-size: 12px;
  padding: 10px;
  transform: translate(-50%);
}

#gallery {
  line-height: 0;
  margin-top: 70px;
  min-height: 30px;
  margin-bottom: 50px;
}
#gallery img {
  width: 33.3333333333%;
  display: inline-block;
  margin: 0;
  padding: 0;
}
@media (max-width: 1000px) {
  #gallery img {
    width: 50%;
  }
}

.kontakt {
  background: #004875;
  text-align: center;
  padding: 100px 50px 250px;
}
@media (max-width: 750px) {
  .kontakt {
    padding: 50px 0;
  }
}
.kontakt h1 {
  text-align: center;
  font-size: 4em;
  color: #7CBCD8;
  line-height: 1;
}
@media (max-width: 600px) {
  .kontakt h1 {
    font-size: 2.5em;
  }
}
.kontakt h2 {
  color: white;
  font-weight: 300;
  font-size: 1.3em;
}
.kontakt h2 strong {
  color: white;
  font-size: 0.9em;
}
.kontakt h2 span {
  color: white;
  font-weight: 700;
  font-size: 1em;
}
.kontakt p {
  color: white;
  font-weight: 300;
  font-size: 1.5em;
}
.kontakt p strong {
  color: white;
  font-size: 0.9em;
}

.alert-success {
  color: white;
  display: inline-block;
  margin: 40px auto 0;
  text-align: center;
}
.alert-success p {
  text-align: left;
  padding-top: 10px;
  font-size: 1em;
}
.alert-success p:before {
  content: "✓";
  color: white;
  margin-right: 10px;
  font-size: 1.5em;
}
.alert-success p b {
  color: white;
  font-weight: 700;
  font-size: 1em;
  text-align: left;
}

.alert-danger {
  color: white;
}
.alert-danger p {
  text-align: left;
  padding-top: 10px;
  font-size: 1em;
}
.alert-danger p:before {
  content: "";
  background: url("../img/achtung.png") left top no-repeat/contain;
  width: 25px;
  height: 30px;
  position: absolute;
}
.alert-danger p b {
  color: white;
  font-weight: 700;
  font-size: 1em;
  margin-left: 40px;
  text-align: left;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0 auto;
  padding: 0;
  width: 90%;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat;
}

/* Icons */
@font-face {
  font-family: "slick";
  src: url("../fonts/slick.eot");
  src: url("../fonts/slick.eot?#iefix") format("embedded-opentype"), url("../fonts/slick.woff") format("woff"), url("../fonts/slick.ttf") format("truetype"), url("../fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: black;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}
.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}
.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}
.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: #1c8598;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: 0;
}
[dir=rtl] .slick-prev {
  left: auto;
  right: 0;
}
.slick-prev:before {
  content: "←";
}
[dir=rtl] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: 0;
}
[dir=rtl] .slick-next {
  left: 0;
  right: auto;
}
.slick-next:before {
  content: "→";
  color: #1c8598;
}
[dir=rtl] .slick-next:before {
  content: "←";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}
.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}

.headerAnmeldung {
  background: url(../img/header_anmeldung.jpg) no-repeat;
  background-position: 10%;
  background-size: cover;
  width: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
}
.headerAnmeldung .anmedlungLeer {
  flex: 1 0 0;
}
.headerAnmeldung .anmeldungText {
  flex: 1 0 0;
  margin: 10vw 50px;
}
@media (max-width: 600px) {
  .headerAnmeldung .anmeldungText {
    margin: 3rem auto;
    padding: 1rem;
    min-width: 100%;
  }
  .headerAnmeldung .anmeldungText .anmeldungLeer {
    display: none;
  }
}
.headerAnmeldung .anmeldungText h2 {
  color: #074973;
  line-height: 1.2;
  text-align: left;
  font-size: 2.5em;
}
.headerAnmeldung .anmeldungText h3 {
  line-height: 1.2;
  text-align: left;
  color: #1E9DC7;
  font-size: 2em;
}
@media (max-width: 480px) {
  .headerAnmeldung .anmeldungText h3 {
    max-width: 150px;
  }
}
.headerAnmeldung .anmeldungText .btn-gelb {
  line-height: 30px;
  padding: 5px 10px;
}
.headerAnmeldung .anmeldungText .btn-gelb i {
  margin: 0;
  padding: 0;
  position: relative;
  top: 2px;
}
.anmeldung {
  padding: 3rem 1rem;
}
.anmeldung hr {
  border: none;
  background: #fff;
  height: 1px;
  width: 100%;
  margin: 0 auto;
  max-width: 800px;
}
.anmeldung b, .anmeldung sup {
  color: white !important;
}
.anmeldung .download {
  margin: 2rem 0;
}
.anmeldung .download a {
  font-size: inherit;
  color: inherit;
  text-decoration: underline;
}
.anmeldung #kontodaten {
  display: none;
}
.anmeldung #lastschrift:checked ~ #kontodaten {
  display: block;
}
.anmeldung #form {
  padding: 0 1rem;
  width: 100%;
  max-width: 800px;
  margin-top: 0 !important;
}
.anmeldung #form input[type=radio] {
  float: left;
}
.anmeldung select {
  border: none;
  border-radius: 0;
  font-size: 1em;
  width: 100%;
  height: 35px;
  border-radius: none;
  outline: none;
  background: white;
  border-radius: 0px !important;
}

.anmeldung__preise {
  margin: 3rem auto 0;
  padding: 1rem;
}
.anmeldung__preise h3 {
  font-size: 2rem;
}
.anmeldung__preise ul {
  width: 100%;
  max-width: 800px;
  display: flex;
  justify-content: center;
  margin: 2rem auto;
}
.anmeldung__preise ul li {
  flex: 1 0 0;
  background: #fff;
  margin: 0.5rem;
}
.anmeldung__preise ul li strong {
  display: block;
  background: #d1e7f1;
  padding: 0.5rem 1rem;
  color: #004875;
}
.anmeldung__preise ul li p {
  display: block;
  color: #333;
  font-size: 1rem;
  padding: 2rem 1rem;
}
.anmeldung__preise ul li p .anmeldung__preis {
  display: block;
  font-size: 2rem;
  font-weight: bold;
  color: #1E9DC7;
  margin: 0;
  line-height: 1;
}
@media (max-width: 540px) {
  .anmeldung__preise ul {
    display: block;
  }
}

.height {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.anmeldungContent h2, .anmeldungContent p {
  text-align: center;
}

.cc_banner-wrapper .cc_container {
  width: 100vw;
  padding: 10px;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10000000000000000;
  background: white;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cc_banner-wrapper .cc_container a.cc_btn_decline,
.cc_banner-wrapper .cc_container a.cc_btn_accept_all {
  order: 2;
  padding: 5px 10px;
  color: white;
  background: #1E9DC7;
  border-radius: 4px;
  margin: 10px;
  cursor: pointer;
  user-select: none;
}
.cc_banner-wrapper .cc_container a.cc_btn_decline {
  background: #d1e7f1 !important;
  color: #1E9DC7;
}
.cc_banner-wrapper .cc_container .cc_message {
  padding: 10px;
  font-size: 0.925rem;
  color: #000;
  max-width: 90%;
}
.cc_banner-wrapper .cc_container .cc_message a {
  font-size: inherit;
}
@media (max-width: 700px) {
  .cc_banner-wrapper .cc_container {
    flex-direction: column;
    align-items: flex-start;
  }
  .cc_banner-wrapper .cc_container .cc_message {
    max-width: 100%;
  }
}

#ticket--intro {
  padding-top: 100px;
}
#ticket--intro p {
  max-width: 1000px;
}

.content-idee {
  padding-top: 0 !important;
}

#uploadForm {
  margin: 40px 0;
  background: none;
  padding: 0;
}
#uploadForm #confirmation {
  position: relative;
  display: flex;
  margin-top: 20px;
}
#uploadForm input[type=email], #uploadForm input[type=text] {
  border: 1px solid lightgrey;
}
#uploadForm input[type=checkbox] {
  width: 20px;
  height: 20px;
  top: 0;
  margin: 0;
  margin-right: 10px;
}
#uploadForm label[for=confirm] {
  position: relative;
  cursor: pointer;
  display: inline-block;
}
#uploadForm select {
  margin: 15px 0;
  padding: 5px 10px;
  border-radius: 4px;
  border: 1px solid lightgrey;
}
#uploadForm input[type=file] {
  padding: 0;
  left: 0;
  top: 0;
  display: none;
}
#uploadForm #file-label {
  position: relative;
  left: 0;
  background: #667477;
  display: inline-block;
  padding: 10px 20px;
  color: white;
  cursor: pointer;
  transition: all 300ms ease;
}
#uploadForm #file-label:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}
#uploadForm #filename {
  display: inline-block;
  margin-left: 10px;
}
#uploadForm #tel {
  position: absolute;
  visibility: hidden;
  pointer-events: none;
  left: -200vw;
}
#uploadForm button[type=submit] {
  appearance: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 20px;
  margin-bottom: 10px;
  padding: 20px 25px;
  background: #FFBB00;
  width: 100%;
  color: white;
  border: none;
  outline: none;
  box-sizing: border-box;
  transition: all 300ms ease;
  color: white !important;
}
#uploadForm button[type=submit] span {
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  color: white !important;
}
#uploadForm button[type=submit] img {
  transition: all 300ms ease;
  width: 30px;
  display: inline-block;
  margin-right: 10px;
  position: relative;
  top: 0;
}
#uploadForm button[type=submit]:hover {
  background: #ffbf0f;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.15);
}
#uploadForm button[type=submit]:hover img {
  top: -5px;
}
#uploadForm em {
  font-weight: 600;
  font-style: normal;
  font-size: 0.825em;
  text-align: center;
  margin: 0 auto 30px;
  color: grey;
  display: block;
}

#slideshow {
  width: 100%;
  height: 35vw;
  max-height: 460px;
  min-height: 200px;
  background: lightgrey;
}
#slideshow .slick-list, #slideshow .slick-track {
  height: 100%;
  width: 100%;
}
#slideshow .slick-dots {
  position: absolute;
  left: 50%;
  bottom: 10px;
  transform: translate(-50%);
}
#slideshow .slick-dots li button {
  display: inline-block;
  border-radius: 50%;
  background: white;
  height: 14px;
  width: 14px;
}
#slideshow .slick-dots li button:before {
  content: "";
  background: #1E9DC7;
  opacity: 0;
  width: 10px;
  height: 10px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
}
#slideshow .slick-dots li.slick-active button:before {
  opacity: 1;
}
#slideshow .slide {
  width: 100%;
  height: 100%;
}
#slideshow .slide.slide1 {
  background: url("../img/idee_header1.jpg") top left;
  background-size: cover;
}
#slideshow .slide.slide2 {
  background: url("../img/idee_header2.jpg") center right;
  background-size: cover;
}
#slideshow .slide.slide3 {
  background: url("../img/idee_header3.jpg") left center;
  background-size: cover;
}
#slideshow .slide .slide-content {
  display: flex;
  justify-content: center;
  height: 100%;
  width: 60%;
  max-width: 500px;
  padding: 30px;
  flex-direction: column;
}
#slideshow .slide .slide-content h2, #slideshow .slide .slide-content h3, #slideshow .slide .slide-content h4, #slideshow .slide .slide-content h5 {
  color: white;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
  line-height: 1.2;
}
#slideshow .slide .slide-content h2 {
  font-size: 5em;
}
#slideshow .slide .slide-content h3 {
  font-size: 2em;
}
#slideshow .slide .slide-content h4 {
  font-size: 2em;
}
#slideshow .slide .slide-content h5 {
  font-weight: 400;
  font-size: 1em;
  margin-top: 20px;
}
@media (max-width: 600px) {
  #slideshow .slide .slide-content h2 {
    font-size: 3em;
  }
  #slideshow .slide .slide-content h3, #slideshow .slide .slide-content h4 {
    font-size: 1.4em;
  }
  #slideshow .slide .slide-content h5 {
    font-size: 0.825em;
  }
}

.centered-content {
  padding: 60px 20px;
}
.centered-content h1 {
  line-height: 1;
  margin-bottom: 20px;
  text-transform: uppercase;
}
.centered-content p {
  max-width: 600px;
  margin: 0 auto;
  font-size: 1.2em;
}

.col-1.padding {
  min-width: 270px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 6vw 5vw;
}
.col-1.padding h2 {
  margin-bottom: 20px;
  text-transform: uppercase;
  line-height: 1.2;
}
.col-1.padding p {
  max-width: 500px;
}

.image-idee {
  background: url("../img/idee.jpg") center;
  background-size: cover;
}

.image-mitmachen {
  background: url("../img/mitmachen.jpg") bottom center;
  background-size: cover;
}

.image-opti {
  background: url("../img/dohr.jpg") top center;
  background-size: cover;
}

.image-upload {
  background: url("../img/upload.jpg") center;
  background-size: cover;
}

.image-jury {
  background: url("../img/jury.jpg") center;
  background-size: cover;
}

.sr-idee {
  align-items: none;
}
.sr-idee #opti_thumb {
  width: 100%;
  height: auto;
  display: block;
  margin: 20px 0;
}
.sr-idee .bubble {
  display: block;
  padding: 20px;
  background: #1E9DC7;
  margin-top: 20px;
  margin-bottom: 20px;
  position: relative;
}
.sr-idee .bubble:before {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  background: inherit;
  left: 0;
  top: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
}
.sr-idee .bubble p, .sr-idee .bubble b {
  color: white;
}
@media (max-width: 740px) {
  .sr-idee {
    display: block;
  }
  .sr-idee div[class*=image-] {
    height: 40vw;
    min-height: 300px;
  }
  .sr-idee div[class*=image-].image-mitmachen {
    background-position: bottom 10% center;
  }
  .sr-idee div[class*=image-].image-opti {
    background-position: top 10% center;
  }
  .sr-idee .image-upload {
    order: 1;
  }
  .sr-idee .bubble {
    margin-top: 0;
  }
  .sr-idee .bubble:before {
    left: 50%;
    top: 0;
  }
}

.order2 {
  order: 2;
}

#modal {
  position: fixed;
  padding: 40px 40px;
  background: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999999999999;
  min-width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2), 0 0 10px 5000px rgba(0, 0, 0, 0.8);
  border-radius: 4px;
}
#modal a {
  background: #6bd06b;
  text-align: center;
  color: white;
  margin: 20px 0 0;
  padding: 10px 15px;
  display: block;
  width: 100%;
}

.modal {
  display: none;
  position: fixed;
  padding: 40px 40px;
  background: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999999999999;
  width: 90vw;
  height: 90vh;
  overflow: auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2), 0 0 10px 5000px rgba(0, 0, 0, 0.8);
  border-radius: 4px;
}
.modal h2 {
  margin-bottom: 20px;
}
.modal p {
  margin-bottom: 30px;
}
.modal a {
  position: relative;
  margin-bottom: 40px;
}

.idee--iframe {
  position: relative;
}
.idee--iframe img {
  max-width: 100%;
  visibility: hidden;
}
.idee--iframe iframe {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .allesponsoren .hauptsponsoren, .allesponsoren .sponsoren {
    display: flex;
    flex-wrap: wrap;
    margin: 30px;
    justify-content: center;
  }
  .allesponsoren .hauptsponsoren .s2, .allesponsoren .sponsoren .s2 {
    min-width: 150px;
    margin: 10px;
  }
  .headerAnmeldung {
    min-height: 300px;
  }
  .headerAnmeldung .anmeldungText {
    margin: 35px 50px;
  }
}
@media (-ms-high-contrast: none) and (max-width: 866px), (-ms-high-contrast: active) and (max-width: 866px) {
  .headerAnmeldung .anmeldungText {
    text-align: center;
  }
}
@media (-ms-high-contrast: none) and (max-width: 866px), (-ms-high-contrast: active) and (max-width: 866px) {
  .anmeldungContent h2, .anmeldungContent p {
    text-align: left;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .headerTrendforum .headline {
    margin-top: 80px !important;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .spectarisContent .downloads {
    min-width: 300px;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .sponsorenHeader div {
    margin: 80px auto 0;
    padding-top: 0;
  }
}
@media (-ms-high-contrast: none) and (max-width: 750px), (-ms-high-contrast: active) and (max-width: 750px) {
  .sponsorenHeader div {
    margin: 40px auto 0;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .impressionen {
    display: flex;
    flex-wrap: wrap;
  }
  .impressionen .gridItem {
    width: 33.33%;
  }
}
@media (-ms-high-contrast: none) and (max-width: 655px), (-ms-high-contrast: active) and (max-width: 655px) {
  .impressionen .gridItem {
    width: 50%;
  }
  .impressionen .gridItem #img5 {
    display: none !important;
  }
  .impressionen .gridItem #img6 {
    display: none !important;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  #pressemitteilungen .soft-row .col-3 {
    margin: 50px 0 0 50px !important;
    min-width: 0;
  }
}
@media (-ms-high-contrast: none) and (max-width: 600px), (-ms-high-contrast: active) and (max-width: 600px) {
  #pressemitteilungen .soft-row .col-3 {
    margin: 50px 0 0 0px !important;
  }
}
#tnb {
  padding-top: 10vw;
}
#tnb h2 {
  margin-bottom: 20px;
}
#tnb p {
  margin-bottom: 30px;
}
#tnb a {
  position: relative;
  margin-bottom: 40px;
}

.stickies {
  position: fixed;
  top: 350px;
  right: 0;
  z-index: 10;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  transition: all 300ms ease;
}
.stickies.hidden {
  transform: translateX(100%);
}
.stickies .sticky {
  padding: 1rem 1rem 0.6rem;
  background: #FFBB00;
  fill: #FFBB00;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: white;
  transition: all 300ms ease;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
  width: 110px;
  height: auto;
  text-align: center;
  margin-bottom: 10px;
  cursor: pointer;
}
.stickies .sticky * {
  line-height: 1;
}
.stickies .sticky strong {
  color: white;
  display: inline-block;
}
.stickies .sticky small {
  font-size: 70%;
  color: white;
}
.stickies .sticky:hover {
  width: 140px;
  padding: 1rem 1.5rem 0.6rem;
}
.stickies .sticky:before {
  content: "";
  height: 100%;
  background: url("../img/sticky-zigzag.svg") top -1px right repeat;
  background-size: 10px;
  width: 10px;
  position: absolute;
  display: block;
  right: 99%;
  top: 0;
}

.boxed--content {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}
.boxed--content section {
  padding: 0;
}

#MyEvent {
  height: 100%;
  min-height: 100vh;
}

.stream--content {
  background: #d1e7f1;
  background-size: cover;
  background-attachment: fixed;
}
.stream--content #ticket--intro, .stream--content .fullwidth {
  background: transparent;
  padding: 0;
}
.stream--content .chat--container {
  grid-area: chat;
  background: #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: center;
}
.stream--content .video--container {
  width: 100%;
  padding-bottom: 1rem;
  margin: 0rem auto;
  display: grid;
  grid-gap: 1rem;
  grid-template-areas: "aside1 video aside2" "aside1 video aside2";
  grid-template-columns: 150px 1fr 150px;
  grid-template-rows: auto 1fr;
}
.stream--content .video--container .hauptsponsoren {
  grid-area: aside1;
}
.stream--content .video--container .hauptsponsoren, .stream--content .video--container .nebensponsoren {
  background: rgba(255, 255, 255, 0.9);
  display: block;
  padding: 30px 10px;
}
.stream--content .video--container .hauptsponsoren h3, .stream--content .video--container .nebensponsoren h3 {
  color: #1E9DC7;
  text-align: center;
  display: block;
  font-size: 1rem;
  margin-bottom: 10px;
}
.stream--content .video--container .hauptsponsoren a, .stream--content .video--container .nebensponsoren a {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 20px;
  max-width: 80%;
}
.stream--content .video--container .hauptsponsoren a img, .stream--content .video--container .nebensponsoren a img {
  max-width: 100%;
  min-width: 45px;
}
.stream--content .video--container .nebensponsoren {
  grid-area: aside2;
}
.stream--content .video--container .iframe--wrapper {
  grid-area: video;
  background: white;
  padding-bottom: 0;
  height: auto;
  min-height: 640px;
}
.stream--content .video--container .iframe--wrapper iframe {
  height: 100% !important;
}
@media (max-width: 960px) {
  .stream--content .video--container {
    display: block;
    padding: 1rem 0;
    padding-top: 0;
    padding-bottom: 0;
  }
  .stream--content .video--container .iframe--wrapper {
    margin-bottom: 1rem;
  }
  .stream--content .video--container .chat--container {
    padding: 2rem;
    margin-bottom: 1rem;
  }
  .stream--content .video--container .hauptsponsoren, .stream--content .video--container .nebensponsoren {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1rem;
  }
  .stream--content .video--container .hauptsponsoren h3, .stream--content .video--container .nebensponsoren h3 {
    flex: 1 0 100%;
    font-size: 2rem;
  }
  .stream--content .video--container .hauptsponsoren a, .stream--content .video--container .nebensponsoren a {
    flex: 1 0 30%;
    max-width: 100px;
  }
}

.chats--container {
  text-align: left;
  padding: 0;
}
.chats--container iframe {
  position: relative !important;
}
.chats--container p {
  margin-bottom: 10px;
}
.chats--container strong {
  color: #1E9DC7;
}

.pausentalk {
  text-align: center;
  padding: 0rem 1rem 0rem;
}
.pausentalk ul {
  margin-top: 2rem;
}
.pausentalk ul li {
  margin-bottom: 10px;
}
.pausentalk .container {
  padding-bottom: 1rem;
}
.pausentalk .container .content {
  padding: 2rem;
  background: white;
}
@media (max-width: 600px) {
  .pausentalk .container .content {
    padding: 2rem 1rem;
  }
}
.pausentalk .container h2 {
  color: #1E9DC7;
  margin-bottom: 10px;
}
.pausentalk .btn-gelb {
  margin: 2rem auto 0;
  background: #1E9DC7;
  display: inline-block;
}
.pausentalk .chats--container {
  text-align: left;
  padding: 0;
}
.pausentalk .chats--container iframe {
  position: relative !important;
}
.pausentalk .chats--container p {
  margin-bottom: 10px;
}
.pausentalk .chats--container strong {
  color: #1E9DC7;
}

.programm {
  text-align: center;
  background: rgba(255, 255, 255, 0.9);
  padding: 2rem 1rem 10px !important;
  margin: 0rem 0 2rem;
}
.programm .programm--container .programm--item {
  background: #e5f1f7;
  margin: 1rem 0;
  padding: 2rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
@media (max-width: 600px) {
  .programm .programm--container .programm--item {
    display: block;
  }
}
.programm .programm--container .programm--item.programm--short .programm--content h3 {
  margin-bottom: 0 !important;
}
.programm .programm--container .programm--item.programm--short .programm--content h4 {
  margin-top: 1rem !important;
  margin-bottom: 0 !important;
}
.programm .programm--container .programm--item .programm--time {
  width: 180px;
  text-align: left;
  font-weight: bold;
}
@media (max-width: 600px) {
  .programm .programm--container .programm--item .programm--time {
    width: 100%;
    margin-bottom: 1rem;
    padding-bottom: 5px;
    border-bottom: 1px solid #1E9DC7;
  }
}
.programm .programm--container .programm--item .programm--content {
  flex: 1 0 0;
  text-align: left;
}
.programm .programm--container .programm--item .programm--content h3 {
  color: #1E9DC7;
  margin-bottom: 10px;
}
.programm .programm--container .programm--item .programm--content h4, .programm .programm--container .programm--item .programm--content h4 * {
  font-size: 1rem;
  font-style: italic;
  margin-bottom: 10px;
}
.programm .programm--container .programm--item .programm--content ul li {
  list-style-type: disc;
  margin-left: 20px;
  margin-top: 5px;
}
.programm .programm--container .programm--item.pause {
  background: #1E9DC7;
}
.programm .programm--container .programm--item.pause * {
  color: white !important;
}
@media (max-width: 600px) {
  .programm .programm--container .programm--item.pause .programm--time {
    border-bottom: 1px solid #d1e7f1;
  }
}

.special {
  border: 1px solid rgba(30, 157, 199, 0.2);
  position: relative;
}
.special:after {
  content: "i";
  font-family: serif;
  font-size: 10px;
  line-height: 16px;
  position: absolute;
  color: white;
  text-align: center;
  right: -8px;
  top: -8px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #1E9DC7;
}

#info .container {
  display: flex;
  align-items: center;
  padding: 1rem 2rem;
  background: white;
  margin-bottom: 1rem;
}
#info .container .special {
  border: none;
  margin-right: 0.7rem;
}

.ticket--modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 100000000000000020;
  background: rgba(0, 0, 0, 0.4);
  padding: 1rem;
}
@media (min-width: 400px) {
  .ticket--modal {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
  }
}
.ticket--modal-inner {
  background: white;
  padding: 2rem 2rem 2rem;
  position: relative;
  max-width: 60rem;
  overflow: auto;
  max-height: 95vh;
}
.ticket--modal-inner span {
  position: absolute;
  right: 1rem;
  line-height: 1;
  top: 1rem;
  font-size: 2rem;
  cursor: pointer;
  z-index: 1;
}
.ticket--modal-inner a {
  display: block;
  margin-top: 2rem;
  text-align: center;
}
.ticket--modal-inner .covid__info {
  text-align: center;
  padding-top: 1rem;
}
.ticket--modal-inner .covid__info a {
  margin: 0;
  display: inline;
}