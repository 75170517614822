form
    position: relative
    overflow: hidden
    max-width: 600px
    background: #efefef
    padding: 20px
    input[type="text"],
    input[type="email"],
    input[type="tel"],
    input[type="range"],
    textarea
        width: 100%
        margin-bottom: 20px
        padding: 5px 10px
        margin-top: 5px
        outline: none
        border: none
    input
        padding: 5px 10px
        outline: none
        border: none
        margin-bottom: 20px
        margin-top: 5px
    label
        display: inline-block
    .labelRadio
        margin-right: 20px
    input[type="checkbox"],
    input[type="radio"]
        margin-right: 5px

    #url2, #url
        position: absolute
        left: 2000px
        opacity: 0
.kontakt, .sponsor-werden, .newsletterbestellen
    #form 
        text-align: left
        align-items: center
        margin: 50px auto
        background: transparent
    #submit 
        background: $gelb
        color: white
        padding: 10px 20px
        font-size: 1.2em
        cursor: pointer
        transition: all 200ms ease
        &:hover
            background: #f9c963
    label
        color: white
        display: inline-block
        max-width: 90%
        margin: 0 0 10px
        sup 
            color: white
    input[type=checkbox] 
        width: 16px
        height: 16px
        text-align: left
        display: inline-block
        vertical-align: top
    textarea
        height: 200px
    