.cc_banner-wrapper
	.cc_container
		width: 100vw
		padding: 10px
		position: fixed
		bottom: 0
		left: 0
		z-index: 9999999999999999
		background: white
		box-shadow: 0 0 20px rgba(black, 0.2)
		display: flex
		align-items: center
		justify-content: space-between
		a.cc_btn_decline,
		a.cc_btn_accept_all
			order: 2
			padding: 5px 10px
			color: white
			background: $blau
			border-radius: 4px
			margin: 10px
			cursor: pointer
			user-select: none
		a.cc_btn_decline
			background: $hellblau!important
			color: $blau
		.cc_message
			padding: 10px
			font-size: 0.925rem
			color: #000
			max-width: 90% 
			a
				font-size: inherit
		@media (max-width: 700px)
			flex-direction: column
			align-items: flex-start
			.cc_message
				max-width: 100%