.spectaris
    .spectarisContent
        padding-top: 50px
        display: flex
        flex-wrap: wrap
        justify-content: space-between!important
        
        .verbandsstruktur 
            flex: 2 0 0
            margin: 50px 50px 0 100px
            min-width: 300px
            @media (max-width: 914px)
                margin: 50px 20px 0 20px
            @media (max-width: 400px)
                min-width: 200px
            .verbandsstrukturImg
                margin: 50px 0 50px 0


        .downloads 
            flex: 1 0 0
            margin: 50px 100px 0 50px
            @media (max-width: 914px)
                margin: 50px 0 0
            .links
                background: $gelb
                padding: 20px 20px 20px 20px
                min-width: 300px
                h2
                    color: white
                a
                    color: white
                    &:hover 
                        color: black
            img 
                margin: 0 10px 0 10px
            
            .spectarisKontakt 
                padding-top: 20px
                background: #f2f2f2
                padding: 20px 20px 20px 20px
                min-width: 300px

