*
    box-sizing: border-box
    margin: 0
    padding: 0
    list-style: none
    text-decoration: none
    // -webkit-font-smoothing: antialiased
    line-height: 1.5
    color: #333
    position: relative
body
    min-height: 100vh
    display: flex
    flex-direction: column
    justify-content: space-between
    padding-top: 70px
    @media (max-width: 1150px)
        padding-top: 58px
    &.scrolled
        padding-top: 40px
    
.boxed-content
    width: 100%
    max-width: 1200px
    margin: 0 auto
    //background: #fff
    // padding-top: 70px 
    &:scrolled
        // padding-top: 50px
    @media (max-width: 1150px)
        // padding-top: 58px
// .ausnahme
//     padding-top: 0px!important

body 
    background-color: #FFFFFF
    // background-image: url(../img/muster.png) 
    // background-repeat: repeat 
    // background-position: right top 
    // background-attachment: fixed
section 
    background: white
a 
    &:hover
        color: $blau
