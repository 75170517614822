.kontakt 
    background: $dunkelblau
    text-align: center
    padding: 100px 50px 250px
    @media (max-width: 750px)
        padding: 50px 0 
    h1 
        text-align: center
        font-size: 4em
        color: #7CBCD8
        line-height: 1
        @media (max-width: 600px)
            font-size: 2.5em
    h2 
        color: white
        font-weight: 300
        font-size: 1.3em
        strong 
            color: white
            font-size: 0.9em
        span 
            color: white
            font-weight: 700
            font-size: 1em
    p 
        color: white
        font-weight: 300
        font-size: 1.5em
        strong 
            color: white
            font-size: 0.9em

.alert-success
    color: white
    display: inline-block
    margin: 40px auto 0
    text-align: center
    p 
        text-align: left
        padding-top: 10px
        font-size: 1em
        &:before 
            content: "✓"
            color: white
            margin-right: 10px
            font-size: 1.5em    
        b 
            color: white
            font-weight: 700
            font-size: 1em
            text-align: left
            
.alert-danger
    color: white
    p 
        text-align: left
        padding-top: 10px
        font-size: 1em
        &:before 
            content: ""
            background: url('../img/achtung.png') left top no-repeat / contain
            width: 25px
            height: 30px
            position: absolute
        b
            color: white
            font-weight: 700
            font-size: 1em
            margin-left: 40px
            text-align: left