.stickies
	position: fixed
	top: 350px
	right: 0
	z-index: 10
	display: flex
	align-items: flex-end
	flex-direction: column
	transition: all 300ms ease
	&.hidden
		transform: translateX(100%)

	.sticky
		padding: 1rem 1rem 0.6rem
		background: $gelb
		fill: $gelb
		position: relative
		display: flex
		align-items: center
		justify-content: center
		flex-direction: column
		color: white
		transition: all 300ms ease
		box-shadow: 0 0 1rem rgba(black, 0.2)
		width: 110px
		height: auto
		text-align: center
		margin-bottom: 10px
		cursor: pointer
		*
			line-height: 1
		strong
			color: white
			display: inline-block
		small
			font-size: 70%
			color: white
		&:hover
			width: 140px
			padding: 1rem 1.5rem 0.6rem
		&:before
			content: ''
			height: 100%
			background: url('../img/sticky-zigzag.svg') top -1px right repeat
			background-size: 10px
			width: 10px
			position: absolute
			display: block
			right: 99%
			top: 0