.headerAnmeldung
    background: url(../img/header_anmeldung.jpg) no-repeat 
    background-position: 10%
    background-size: cover
    width: 100%
    position: relative
    //height: 40vh
    //min-height: auto
    display: flex
    flex-wrap: wrap
    .anmedlungLeer
        //min-width: 500px
        //min-height: 30vh
        flex: 1 0 0
    .anmeldungText
        //min-width: 250px
        //text-align: right
        flex: 1 0 0
        margin: 10vw 50px
        @media (max-width: 600px)
            margin: 3rem auto
            padding: 1rem
            //margin: 0 30px
            min-width: 100%
            .anmeldungLeer
                display: none
        h2 
            color: #074973
            line-height: 1.2
            text-align: left
            font-size: 2.5em
        h3
            line-height: 1.2
            text-align: left
            color: $blau
            font-size: 2em
            @media (max-width: 480px)
                max-width: 150px
        .btn-gelb
            line-height: 30px
            padding: 5px 10px
            i
                margin: 0
                padding: 0
                position: relative
                top: 2px

        @media (max-width: 864px)
            //margin-top: 20px
.anmeldung
    padding: 3rem 1rem
    hr
        border: none
        background: #fff
        height: 1px
        width: 100%
        margin: 0 auto
        max-width: 800px
    b, sup
        color: white!important

    .download
        margin: 2rem 0
        a
            font-size: inherit
            color: inherit
            text-decoration: underline
          
    #kontodaten 
        display: none
                
    #lastschrift:checked~#kontodaten 
        display: block
    #form 
        padding: 0 1rem
        width: 100%
        max-width: 800px
        margin-top: 0!important
        input[type=radio]
            float: left
    select
        border: none
        border-radius: 0
        font-size: 1em
        width: 100%
        height: 35px
        border-radius: none
        outline: none
        background: white
        border-radius: 0px!important

              
.anmeldung__preise
    margin: 3rem auto 0
    padding: 1rem
    h3
        font-size: 2rem 
    ul
        width: 100%
        max-width: 800px
        display: flex
        justify-content: center
        margin: 2rem auto
        
        li
            flex: 1 0 0
            background: #fff
            //padding: 1rem
            margin: 0.5rem
            strong
                display: block
                background: $hellblau
                padding: 0.5rem 1rem
                color: $dunkelblau
            p
                display: block
                color: $base-font-color
                font-size: 1rem
                padding: 2rem 1rem
                .anmeldung__preis
                    display: block
                    font-size: 2rem 
                    font-weight: bold
                    color: $blau
                    margin: 0
                    line-height: 1
        @media (max-width: 540px)
            display: block
.height
    min-height: 100vh
    display: flex
    flex-direction: column
.anmeldungContent
    h2, p
        text-align: center
    
    // @media (max-width: 864px)
    //     h2 
    //         margin-top: 220px
    //         text-align: left
    //     p
    //         text-align: left

