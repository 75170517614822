nav
	width: 100%
	background: #fff
	align-items: center
	height: 70px
	z-index: 999999999999999999
	position: fixed
	top: 0
	left: 0
	transition: all 300ms ease
	box-shadow: 0 0 1rem rgba(black, 0.2)
	#logo
		transition: all 300ms ease
		width: 130px
		height: 130px
		z-index: 68
		width: 130px
		top: -10px
		left: 50%
		position: absolute
		transform: translate(-50%)
		img
			width: 100%
			height: auto
			display: block
	#burger
		display: none
	#menu
		display: flex
		align-items: center
		padding: 0 30px 
		width: 100%
		margin: 0 auto
		height: 100%
		justify-content: space-between
		.menupunkte
			display: flex
			align-items: center
			height: 100%
			transition: all 300ms ease
			a, .has-submenu
				height: 100%
				display: flex
				align-items: center
				justify-content: center
				transition: all 300ms ease
				@media (max-width: 1150px)
					padding: 10px
			.has-submenu
				position: relative
				user-select: none
				// cursor: pointer
				padding: 10px 20px
				&:hover
					background: $blau
					color: white
				@media (max-width: 1150px)
					color: white
					text-transform: uppercase
					font-weight: bold
					text-align: center
					display: block
					

				.submenu
					position: relative

					@media (min-width: 1151px)
						position: absolute
						top: 100%
						left: 0
						width: 200%
						height: auto
						background: white
						display: none
					a
						padding: 10px!important
					li
						&:first-of-type
							border-top: 1px solid rgba(white, 0.5)
							margin-top: 10px
				&:hover
					.submenu
						display: block
		.menuItem-right
			align-self: flex-end
		a, span
			padding: 10px 20px
			position: relative
			cursor: pointer
			color: black
			#submenu
				display: none
				position: absolute
				left: 0
				top: 100%
			&:hover, &.active
				background: $blau
				color: white
	&.scrolled
		height: 40px
		#logo
			width: 100px
			height: 100px
			top: -20px

@media (max-width: 1150px)
	nav 
		height: 58px 
		z-index: 999999

		#menu
			padding-left: 0
			padding-right: 0
			position: absolute
			opacity: 0
			transform: translate(110vw)
			transition: all 300ms ease
			height: calc(105vh - 58px)
			display: block
			width: 100%
			right: 0
			top: 58px
			position: absolute
			background: $blau
			padding-top: 70px
			z-index: -10
			a, span
				text-align: right!important
				color: white
				display: block
				padding: 15px 30px
				width: 100%
				border-bottom: 1px solid rgba(white, 0.5)
				font-weight: 600
				text-transform: uppercase
				letter-spacing: 1px
				&:hover
					background: darken(#7CBCD8, 2%)
				#submenu
					position: relative
					left: 0
					top: 0
					width: 100vw
					left: -20px
					margin-top: 20px
					marginbottom: 0
					a
						min-width: 100%
						text-align: right
						padding: 10px 45px 10px 25px
						&:hover
							left: 0
							padding-right: 30px
							background: lighten($blau, 7%)
			.menupunkte
				height: auto
				display: block
				margin-top: 0!important
				a
					height: auto
			&.active
				transform: translate(0)
				opacity: 1


		#burger
			display: block
			width: 30px
			height: 30px
			cursor: pointer
			position: absolute
			right: 10px
			top: 50%
			transform: translateY(-50%)
			transition: all 300ms ease
			span
				width: 24px
				height: 2px
				background: $blau
				display: block
				border-radius: 4px
				position: absolute
				left: 0
				top: 50%
				transform: translateY(-50%)
				transition: all 300ms ease
				&:before
					content: ""
					position: absolute
					width: 100%
					height: 100%
					background: inherit
					top: -7px
					transition: all 300ms ease
				&:after
					content: ""
					position: absolute
					width: 100%
					height: 100%
					background: inherit
					top: 7px	
					transition: all 300ms ease   
			&.active
				span
					transform: rotate(45deg)
					&:before
						width: 0
						top: 0
					&:after
						transform: rotate(-90deg)
						top: 0
		&.scrolled
			height: 40px
			#logo
				width: 80px
			#menu
				top: 40px
@media (max-width: 750px)
	nav
		#logo
			width: 100px
			height: 100px
			top: -20px


#sticky
	position: fixed
	right: 0
	top: 50%
	z-index: 99999
	padding: 0
	.sticky
		padding: 10px 20px 10px 10px
		background: #fff
		margin-bottom: 20px
		text-align: center
		display: block
		box-shadow: 0 0 10px rgba(black, 0.15)
		transition: all 300ms ease
		right: -10px
		&:hover
			right: 0px

		h4
			font-weight: 500
			font-size: 0.825em
			transition: all 300ms ease
		img
			height: 50px
			transition: all 300ms ease
		
	&.scrolled
		// padding: 10px
		// padding-right: 20px
		h4
			display: none
		img
			height: 40px
	@media (max-width: 600px)
		&.scrolled
			background: transparent
			box-shadow: none  
			justify-content: center
			padding-bottom: 0			  
			.sticky
				width: 60px
				margin: 0 2px
				box-shadow: 0 0 10px rgba(black, 0.15)
				img
					height: 30px
					margin: 0
		

	@media (max-width: 600px)
		top: auto
		bottom: 0
		width: 100%
		display: flex
		padding: 0
		box-shadow: 0 0 10px rgba(black, 0.15)
		.sticky
			width: 100%
			margin: 0
			padding: 10px
			left: 0
			right: 0
			box-shadow: none
			display: flex
			justify-content: center
			align-items: center
			img
				margin-right: 10px
			&:hover
				background: #eff7f9

			img
				height: 35px
