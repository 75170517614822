.referenten
	background: white
	min-height: 95vh
	.programm
		width: 100%
		max-width: 1200px
		margin: 0 auto
		padding: 100px 2rem 100px
	.boxed--content
		.programm--content
			min-width: 200px
			h4
				text-align: left
				margin-top: 2rem
		.programm--referent
			margin: 20px auto
			img
				margin: 0!important
			@media (min-width: 640px)
				margin: 20px
		@media (max-width: 640px)
			padding: 4rem 1rem
			.referentenContent 
				h3
					font-size: 1.4rem!important
		text-align: center
		img
			max-width: 100%
			height: auto
			margin-bottom: 20px
		h2
			margin-bottom: 20px
		h1 
			text-align: center
			@media (max-width: 654px)
				text-align: left
		p 
			text-align: left
			@media (max-width: 654px)
				text-align: left
		h4 
			text-align: center
			margin-top: 50px
			&:hover
				color: $blau
		
		.referenten
			margin-top: 70px
			display: flex
			flex-wrap: wrap
			justify-content: center
			
			p, h2
				text-align: right
				@media (max-width: 954px)
					text-align: left
			.links
				p, h2
					text-align: left!important
					@media (max-width: 954px)
						text-align: left!important
			span 
				font-style: italic
			.referentenText
				flex: 4 0 0
				min-width: 600px
				@media (max-width: 680px)
					min-width: 400px
				@media (max-width: 580px)
					min-width: 300px
				@media (max-width: 480px)
					min-width: 200px
			.referentenBild
				margin: 20px!important
				width: 150px
				text-align: center
				img 
					width: 150px
					border-radius: 50%
				
		.keynote
			width: 150px !important
			height: 150px !important
			min-width: 200px !important
			position: relative
			margin: 0 auto 20px
			align-items: center
			z-index: 0
			.referentenBild
				width: 100%
				height: 100%
				margin-left: 0 !important
			&:before
				content: ""
				background: #df8e02
				width: 180px
				height: 30px
				bottom: -5px
				left: 50%
				transform: translate(-50%)
				// z-index: -1
				position: absolute
			&:after
				content: "Keynote-Speaker"
				background: #FFBB00
				position: absolute
				left: 50%
				bottom: -20px
				padding: 5px 10px
				color: white
				transform: translate(-50%)
				width: 151px
				text-align: center
				box-sizing: border-box
				border-radius: 0 0 4px 4px
		.rechts
			flex-wrap: wrap-reverse!important

		.referentenWrapper 
			text-align: left
			padding: 0 2rem
	 
			
			h3 
				color: #1E9BC7
				font-size: 24px
				font-weight: 700
				margin-bottom: 20px !important
			  
			em 
				margin-bottom: 20px !important
				font-style: italic
				display: block
			  
			strong 
				font-weight: 700
				display: block
			  
			p 
				color: #5B5B5B
				font-size: 1rem
 
			.soft-row 
				align-items: center
				display: flex
				padding: 0
				margin: 80px 0
			.referentenContent
				flex: 1 0 0
			  
			.soft-row:nth-child(odd) .imageContainer 
				order: 2
				margin-right: 0
				margin-left: 20px
			  
			.soft-row:nth-child(odd) .referentenContent,
			.soft-row:nth-child(odd) h3,
			.soft-row:nth-child(odd) p,
			.soft-row:nth-child(odd) em 
				text-align: right !important
			  

			  
			.imageContainer 
				width: 150px
				height: 150px
				min-width: 150px
				border-radius: 50%
				overflow: hidden
				position: relative
				margin-right: 20px
			  
				img 
					position: absolute
					width: 100%
					height: 100%
					left: 0
					top: 0
			  
			h3 
				margin: 0
			  
			@media (max-width: 900px) 
				.soft-row 
					justify-content: center
					flex-direction: column	 
					align-items: center
					
				.soft-row .imageContainer,
				.soft-row .referentenContent,
				.soft-row .referentenContent h3,
				.soft-row .referentenContent p,
				.soft-row .referentenContent strong,
				.soft-row .referentenContent em 
					text-align: center !important
					order: 1 !important
					
				.imageContainer 
					margin-bottom: 20px
				
			.keynote 
				order: 1 !important
				margin-bottom: 40px
				margin-left: 0 !important			  
				width: 200px !important
				height: 200px !important
				min-width: 200px !important
				position: relative
				order: 2
				margin-left: 20px
				z-index: 0
			
			.keynote .imageContainer 
				width: 100%
				height: 100%
				margin-left: 0 !important
			
			.keynote:after 
				content: "Keynote-Speaker"
				background: #FFBB00
				position: absolute
				left: 50%
				bottom: -20px
				padding: 10px 20px
				color: white
				transform: translate(-50%)
				width: 150px
				text-align: center
				box-sizing: border-box
				border-radius: 0 0 4px 4px
			
			.keynote:before 
				content: ""
				background: #df8e02
				width: 180px
				height: 30px
				bottom: -5px
				left: 50%
				transform: translate(-50%)
				z-index: -1
				position: absolute
				
.program
	background: white
	padding: 2rem
	text-align: left
	&__item
		display: flex
		flex-wrap: wrap
		max-width: 800px
		width: 100%
		margin: 2rem auto
		padding-bottom: 2rem
		align-items: flex-start
		border-bottom: 1px solid $hellblau
		&-time
			color: $gelb
			font-weight: bold
			width: 100px
			margin-right: 1rem
			//padding-right: 1rem
			border-right: 2px solid $gelb
			margin-bottom: 1rem
		&-content
			flex: 2 0 70%
			text-align: left
			h3, p, em, a:not(.program__item-highlight)
				color: $blau
				font-size: 1rem!important
			h3, p, em
				display: block
			p, em
				margin-top: 0.5rem
			
		&-highlight
			display: inline-block
			width: auto
			font-weight: bold
			color: white
			background: $gelb
			padding: 0.3rem 0.8rem
			margin-top: 1rem
			transition: all 300ms ease
			&:hover
				color: white
				box-shadow: 1px 1px 5px rgba(black, 0.15)

#vorabend
	background: none
	padding: 3rem 0
	text-align: center
	h3, h2
		max-width: 800px
		margin: 0 auto
	h3
		color: $dunkelblau
		margin-bottom: 1rem
		display: block
	.soft-row
		flex-direction: column
		text-align: center
		justify-content: center
		align-items: center
		margin: 3rem auto
		max-width: 1000px
		//align-items: center
		.col-1
			p, strong
				text-align: center
		   
	#anfahrt__solar
		width: 100%
		max-width: 400px
		margin-top: 3rem

.iframe--wrapper 
	width: 100%
	height: 0
	padding-bottom: 56.25%
	position: relative
	iframe 
		position: absolute
		width: 100%
		height: 100%
		left: 0
		top: 0