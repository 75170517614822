#footer
    width: 100%
    background: $blau
    padding: 0 20px

    .footerContent
        max-width: 1200px
        align-items: center
        margin: 0 auto
        display: flex
        justify-content: space-between
        @media (max-width: 965px)
            display: block!important
            text-align: center
            padding: 20px 0
        a 
            color: white
            margin-top: 30px
            padding: 0 15px
            max-width: 10%
            order: 2
            &:hover
                color: #FFBB00!important
        div
            margin-top: 30px
            max-width: 40%
            @media (max-width: 965px)
                align-items: center
                margin: 0 auto
                max-width: 100%
        #footerpunkte
            order: 3
            margin-top: 20px
        p 
            color: white
        span 
            color: white
        .mobile 
            display: none
            @media (max-width: 965px)
                display: inline-block
    @media (max-width: 600px)
        padding-bottom: 80px!important 
        &.scrolled
            padding-bottom: 55px!important


       


        
           
       
    
