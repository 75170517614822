.sponsoren2018
	margin-top: 10px
#hauptsponsoren2018
	background: $blau
	padding: 10px
	display: none
.logosSponsoren
	background: #D0E7F2
	box-sizing: border-box
	//max-width: 1200px
	margin: 0 auto
	height: 80px
	position: relative
	overflow: hidden
	@media (max-width: 750px)
		height: 60px
	.logos  
		height: 100%
		position: absolute
		display: flex
		left: 0px
		top: 0
		width: 3400px
		padding: 10px 0
		align-items: center
		justify-content: space-around
		box-sizing: border-box
		animation: logobanner 25s linear infinite
		a 
			flex: 1
			height: 100%
			margin: 0 20px
			position: relative
			overflow: hidden
			img 
				max-height: 100%
				max-width: 130px
				position: absolute
				top: 50%
				left: 50%
				transform: translate(-50%, -50%)

@keyframes logobanner
	0%
		left: 0px
	100%
		left: -1700px
.live--header
	margin-top: 10px
	position: relative
	img
		width: 100%
	.live--heading-right
		right: 0
		text-align: right
		width: 55%
	.live--heading-left
		left: 0
		text-align: left
		width: 45%
		@media (max-width: 650px)
			width: 50%
		@media (max-width: 650px)
			margin-top: 20px
	.live--heading-left,
	.live--heading-right
		bottom: 0
		position: absolute
		padding: 20px
		max-width: 550px
		h2
			color: white
		@media (min-width: 1000px)
			padding: 30px!important
			h2
				font-size: 3rem
		@media (max-width: 650px)
			h2
				font-size: 1.4rem!important
		@media (max-width: 650px)
			position: relative
			width: 100%
			padding: 0 20px
			h2
				color: $blau
				font-size: 1.4rem!important
				text-align: left
		@media (min-width: 471px) and (max-width: 650px)
			padding: 0 40px

#newsbereich
	display: flex
	justify-content: space-between
	text-align: center
	background: white
	flex-wrap: wrap
	h1
		margin-bottom: 20px
	p
		strong
			color: black
			font-size: 18px
			@media (max-width: 640px)
				font-size: 16px
			a
				font: inherit
	.referenten1
		display: flex
		align-items: center
		justify-content: center
		flex: 2 0 0
		padding: 20px
		flex-wrap: wrap
		@media (max-width: 900px)
			display: block
		.gym--bild
			margin-left: 2rem
			@media (max-width: 900px)
				margin-left: 0
			> div
				width: 100%
				// min-width: 280px
			img
				width: 100%
				height: auto
				max-width: 200px
				transform-origin: center
				transform: rotate(5deg)
				box-shadow: 0 0 1rem rgba(black, 0.2)
		
		.col-1
			&:last-child
				padding: 20px
				min-width: 120px
				@media (max-width: 900px)
					padding-top: 0
				@media (max-width: 470px)
					padding: 0
				img
					max-width: 240px
					width: 100%
					@media (max-width: 450px)
						max-width: 150px
			&:first-child
				padding: 20px
				text-align: left!important
				justify-content: flex-start
				@media (max-width: 470px)
					padding: 0
				h2, p
					margin-bottom: 20px
			
				h5 
					margin-top: 30px
		.info--box
			background: $blau
			color: white
			li
				text-align: left
				color: white
				font-weight: bold
				font-size: 18px
				margin-bottom: 10px
				@media (max-width: 640px)
					font-size: 16px

	.newsletter
		flex: 1 0 0
		background: #dcf3fe
		min-width: 300px
		cursor: pointer
		display: flex
		align-items: center
		flex-direction: column
		justify-content: center
		@media (max-width: 768px)
			min-width: 100%
		h5 
			margin-top: 30px   
		img 

			margin: 20px 0
			max-width: 200px
			height: auto
	#referentenImg
		display: flex
		text-align: center
		flex-wrap: wrap
		margin-bottom: 20px
		justify-content: center
		.imageContainer
			flex-wrap: wrap
			width: 90px
			height: 90px
			min-width: 90px
			margin: 20px 30px
			img 
				position: absolute
				width: 100%
				height: 100%
				left: 0
				top: 0
				margin: 0
				overflow: hidden
				border-radius: 50%
		.refName 
			font-size: 0.8em
			margin: 10px 0
		
		
.soft-row
	.col-1
		min-width: 300px
		.content
			padding: 2em
			h3
				color: $blau
				font-size: 2em
			@media (max-width: 900px)
				padding: 1em
				h3
					font-size: 1.4em
		video
			max-width: 100%
#termin
	//width: 100%
	min-width: 300px
	flex: 1 0 0
	margin: 0 auto
	text-align: center
	background: url(../img/berlin.jpeg) no-repeat center
	background-attachment: fixed
	padding: 30px
	//height: 450px
	box-sizing: border-box
	position: relative
	z-index: 0
	display: flex
	flex-wrap: wrap
	flex-direction: column
	align-items: center
	justify-content: center
	@media (max-width: 655px)
		min-height: 300px
	&:before 
		content: ""
		position: absolute
		top: 0
		left: 0
		width: 100%
		height: 100%
		background: #FFAF30
		opacity: 0.8
		z-index: 0
		pointer-events: none
	.h2-anmeldung 
		color: #FFFFFF
		font-size: 3em
		line-height: 1
		letter-spacing: 2px
		margin: 10px 0
		font-weight: 800
		text-transform: uppercase
		@media (max-width: 750px)
			font-size: 2.5em
		@media (max-width: 655px)
			font-size: 2em
			
	.h3-anmeldung 
		color: #FFFFFF
		font-size: 2em
		letter-spacing: 2px
		line-height: 1
		margin: 10px 0
		font-weight: 300
		text-transform: uppercase
		@media (max-width: 750px)
			font-size: 2em
		@media (max-width: 655px)
			font-size: 1em

#pressemitteilungen
	background: $hellblau
	.soft-row 
		display: flex
		padding: 40px
		flex-wrap: wrap
		justify-content: center
		h2 
			font-size: 3em
			@media (max-width: 655px)
				font-size: 2em
				line-height: 1.4
				margin-bottom: 20px
		@media (max-width: 700px)
			display: block
	.col-1 
		display: block
		flex-wrap: wrap		
		flex: 1 0 0
		max-width: 200px
		padding-right: 20px
		margin-right: 30px
		align-items: flex-start
		flex-direction: column
		justify-content: flex-start
		width: 100%
		img 
			height: auto
			margin: 0 10px 10px 0
		@media (max-width: 700px)
			flex-direction: row
			display: flex
			max-width: none
			margin: 0
			padding-right: 0

			img
				flex: 1 0 28%
				min-width: 40px
	.col-3 
		flex: 3 0 0
		@media (max-width: 400px)
			min-width: 250px

.impressionen 
	margin: 0 auto
	display: grid
	grid-template-columns: repeat(3, 1fr)
	position: relative
	@media (max-width: 655px)
		grid-template-columns: repeat(2, 1fr)
	.gridItem 
		min-height: 200px
		max-height: 300px
		height: 20vw
		background-size: cover
		background-position: center
		display: block
	#img1
		background-image: url(../img/joey-kelly_2x.jpg) 
	#img2
		background-image: url(../img/SCHILKE_2021110816495000.jpg)
		background-position: top
		@media (max-width: 655px)
			background-position: 92% 
	#img3
		background-image: url(../img/reinhold-messner.jpg) 
	#img4
		background-image: url(../img/hamaiel003_12.jpg) 
	#img5
		background-image: url(../img/20ec2260.jpg) 
		@media (max-width: 655px)
			display: none
	#img6
		background-image: url(../img/tf2011_324_gauck_weiler_kastalio_071111_2x.jpg) 
		@media (max-width: 655px)
			display: none
	.btn-blau 
		background: #1E9BC7
		text-decoration: none
		color: white
		color: #FFFFFF
		font-weight: 600
		transition: all 300ms ease
		&:hover 
			box-shadow: 0 4px 8px 0 rgba(0,0,0,0.30)
	.btn-blau.impressionen 
		position: absolute
		left: 50%
		top: 50%
		transform: translate(-50%, -50%)
		display: block
		max-width: 600px
		margin: 0
		padding: 10px
		line-height: 30px
		width: 80%
		text-align: center
		font-size: 2rem
		cursor: pointer


.liste
	margin: 0
	li
		list-style-type: disc
		margin-left: 20px
		margin-bottom: 10px


.greeting
	position: relative
	background: $blau
	padding: 2rem
	.greeting--content
		width: 100%
		max-width: 800px
		margin: 0 auto

	*
		color: white
	h3
		color: white
		margin-bottom: 1rem
		font-size: 24px
	a
		color: $gelb
		text-decoration: underline
		font-size: inherit
	.noticket
		background: white
		padding: 1rem
		margin-top: 2rem
		h3
			color: $blau
		*
			color: black
		a
			color: $blau