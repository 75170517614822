.boxed--content
	width: 100%
	max-width: 1200px
	margin: 0 auto
	padding: 0 1rem
	section
		padding: 0

#MyEvent
	height: 100%
	// height: 4rem!important
	// padding-bottom: 76.25%!important
	min-height: 100vh
	
.stream--content
	// background: url('../img/header-stream.jpg') bottom right no-repeat
	background: $hellblau
	background-size: cover
	background-attachment: fixed
	#ticket--intro, .fullwidth
		background: transparent
		padding: 0
	.chat--container
		grid-area: chat
		background: #f2f2f2
		display: flex
		align-items: center
		justify-content: center
	.video--container
		width: 100%
		padding-bottom: 1rem
		margin: 0rem auto
		display: grid
		grid-gap: 1rem
		grid-template-areas: 'aside1 video aside2' 'aside1 video aside2'
		grid-template-columns: 150px 1fr 150px
		grid-template-rows: auto 1fr
		
		.hauptsponsoren
			grid-area: aside1
		.hauptsponsoren, .nebensponsoren
			background: rgba(white, 0.9)
			display: block
			// flex-wrap: wrap
			padding: 30px 10px
			h3
				color: $blau
				text-align: center
				display: block
				font-size: 1rem
				margin-bottom: 10px
			a
				display: flex
				align-items: center
				justify-content: center
				margin: 0 auto 20px
				// flex: 1 0 50%
				max-width: 80%
				img
					max-width: 100%
					min-width: 45px
	
		.nebensponsoren
			grid-area: aside2

		.iframe--wrapper
			grid-area: video
			background: white
			padding-bottom: 0
			height: auto
			min-height: 640px
			iframe
				height: 100%!important
		@media (max-width: 960px)
			display: block
			padding: 1rem 0
			padding-top: 0
			padding-bottom: 0
			.iframe--wrapper
				margin-bottom: 1rem
			.chat--container
				padding: 2rem
				margin-bottom: 1rem
			.hauptsponsoren, .nebensponsoren
				display: flex
				flex-wrap: wrap
				margin-bottom: 1rem

				h3
					flex: 1 0 100%
					font-size: 2rem
				a
					flex: 1 0 30%
					max-width: 100px
.chats--container
	text-align: left
	// height: 300px
	// overflow-y: auto
	padding: 0
	iframe
		position: relative!important
	p
		margin-bottom: 10px
	strong
		color: $blau

.pausentalk
	text-align: center
	padding: 0rem 1rem 0rem
	ul
		margin-top: 2rem
		li
			margin-bottom: 10px

	.container
		padding-bottom: 1rem
		.content
			padding: 2rem
			background: white
			@media (max-width: 600px)
				padding: 2rem 1rem
		h2
			color: $blau
			margin-bottom: 10px
	.btn-gelb
		margin: 2rem auto 0
		background: $blau
		display: inline-block
	.chats--container
		text-align: left
		// height: 300px
		// overflow-y: auto
		padding: 0
		iframe
			position: relative!important
		p
			margin-bottom: 10px
		strong
			color: $blau


.programm
	text-align: center
	// background:  none
	background: rgba(white, 0.9)
	padding: 2rem 1rem 10px!important
	margin: 0rem 0 2rem
	.programm--container
		.programm--item
			background: lighten($hellblau, 5%)
			margin: 1rem 0
			padding: 2rem
			display: flex
			flex-wrap: wrap
			align-items: center
			@media (max-width: 600px)
				display: block
			&.programm--short
				.programm--content
					h3
						margin-bottom: 0!important
					h4
						margin-top: 1rem!important
						margin-bottom: 0!important
			.programm--time
				width: 180px
				text-align: left
				font-weight: bold
				@media (max-width: 600px)
					width: 100%
					margin-bottom: 1rem
					padding-bottom: 5px
					border-bottom: 1px solid $blau
			.programm--content
				flex: 1 0 0
				text-align: left
				h3
					color: $blau
					margin-bottom: 10px
				h4, h4 *
					font-size: 1rem
					font-style: italic
					margin-bottom: 10px
				ul
					li
						list-style-type: disc
						margin-left: 20px
						margin-top: 5px
			
			&.pause
				background: $blau
				*
					color: white!important
				.programm--time
					@media (max-width: 600px)
						border-bottom: 1px solid $hellblau
				



.special
	border: 1px solid rgba($blau, 0.2)
	position: relative
	&:after
		content: "i"
		font-family: serif
		font-size: 10px
		line-height: 16px
		position: absolute
		color: white
		text-align: center
		right: -8px
		top: -8px
		width: 16px
		height: 16px
		border-radius: 50%
		background: $blau


#info
	.container
		display: flex
		align-items: center
		padding: 1rem 2rem
		background: white
		margin-bottom: 1rem
		.special
			border: none
			margin-right: 0.7rem