@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)
    .allesponsoren
        .hauptsponsoren, .sponsoren
            display: flex
            flex-wrap: wrap
            margin: 30px
            justify-content: center
            .s2
                min-width: 150px
                margin: 10px
    .headerAnmeldung
        min-height: 300px
        .anmeldungText
            margin: 35px 50px
            @media (max-width: 866px)
                text-align: center
    .anmeldungContent
        @media (max-width: 866px)
            h2, p
                text-align: left
    .headerTrendforum 
        .headline 
            margin-top: 80px!important
    .spectarisContent
        .downloads
            min-width: 300px
    .sponsorenHeader
        div 
            margin: 80px auto 0
            padding-top: 0
            @media (max-width: 750px)
                margin: 40px auto 0
    .impressionen
        display: flex
        flex-wrap: wrap
        .gridItem 
            width: 33.33%
            @media (max-width: 655px)
                width: 50%
                #img5
                    display: none!important
                #img6
                    display: none!important
    #pressemitteilungen
        .soft-row
            .col-3
                margin: 50px 0 0 50px!important
                min-width: 0
                @media (max-width: 600px)
                    margin: 50px 0 0 0px!important
    