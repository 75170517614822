#toggle
    width: 100%
    dt
        padding: 10px 20px
        cursor: pointer
        background: $main-color
        color: color-contrast($main-color)
        border-radius: 2px
        position: relative
        &:after
            content: "\25BE"
            position: absolute
            right: 20px
            top: 10px
            font-size: inherit
            transform: rotate(0deg)
            transition: all .3s ease
        &.active
            &:after
                transform: rotate(-180deg)

    dd
        padding: 0 20px
        background: lighten($base-font-color, 70%)
        height: 0
        overflow: hidden
        margin-bottom: 10px
        &.active
            height: auto
            padding: 20px

    