.presse
    margin-top: 70px
    h1 
        text-align: center
        margin-bottom: 20px
    #newswrapper
        .jahr 
            display: flex
            flex-wrap: wrap
            justify-content: center
            border-bottom: 1px solid black
            margin: 0 30px 50px
            @media (max-width: 500px)
                margin: 0 10px 50px
        h3
            background: $blau
            max-width: 250px
            padding: 10px
            text-align: center
            font-size: 1.5rem
            box-shadow: 0 0 7px rgba(0, 0, 0, 0.4) 
        h2 
            font-size: 1em!important
            margin-bottom: 10px
            color: #333
            text-align: left
        .beitrag 
            text-align: center
            margin: 30px
            max-width: 300px
            @media (max-width: 500px)
                margin: 30px 10px
            .image 
                max-width: 300px
                max-height: 400px
                text-align: center
                margin: 20px auto
                img 
                    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5)             
                    height: 400px
        .download-btn 
            margin-top: 10px
            padding: 10px 10px
            background: #FFAF30
            color: white
            transition: all 300ms ease
            display: inline-block
            position: relative
            text-decoration: none
            font-size: 0.9em
            width: 100%
            display: flex
            align-items: center
            font-weight: 800
            i 
                font-size: 1rem 
                color: white
                margin-right: 10px
                border-radius: 50px 
                border: 2px solid white
                width: 30px
                height: 30px
                display: flex
                flex-direction: column
                align-items: center
                justify-content: center
            &:hover 
                box-shadow: 0 4px 8px 0 rgba(0,0,0,.3)
