.xjahre
    text-align: center
    @media (max-width: 900px)
        padding: 70px
    @media (max-width: 700px)
        padding: 50px
    @media (max-width: 500px)
        padding: 20px
    #imgSlider
        img 
           max-width: 1000px
           width: 100%    
    #tf-logos
        .logoneu
            padding: 7px
        img 
            width: 100px
            margin: auto
            cursor: pointer
    p 
        margin-bottom: 100px
    #highlights
        margin-bottom: 50px
        .logoneu
            padding: 7px
        img 
            max-width: 1000px
            width: 100%
    h1 
        margin-top: 50px 
        @media (max-width: 900px)
            margin-top: 0px         
    p 
        margin-bottom: 50px

    .videoContent
        max-width: 1200px
        margin: 0 auto 50px
        .iframeWrapper 
            width: 100%
            height: 0
            padding-bottom: 56.25%
            position: relative
            iframe 
                position: absolute
                width: 100%
                height: 100%
                left: 0
                top: 0
    .thumbContainer
        background: #DAF2FE
        max-width: 1200px
        margin: 0 auto
        text-align: center
        #thumbs 
            padding: 20px 10px
            box-sizing: border-box
            margin: 0 auto
            text-align: left
            display: flex
            flex-wrap: wrap
            justify-content: center
            .thumb 
                width: 60px
                height: 45px
                background: red
                margin: 5px!important
                display: inline-block
                cursor: pointer
                line-height: 1
                box-sizing: border-box


    #mainImage 
        width: 100%
        max-width: 1200px
        margin: 0 auto
        max-height: 600px
        min-height: 250px
        height: 50vw
        background: $blau
        overflow: hidden
        position: relative
        margin-top: 20px
        .container 
            display: block
            flex-wrap: nowrap
            width: 100%
            height: 100%  
            .image
                flex: 1 0 0
                width: 100%
                height: 100%
                padding-top: 230px
                position: absolute
                left: 0
                top: 0
                transition: opacity 1500ms ease
                opacity: 0
                &.active
                    opacity: 1
           
                
        #prev 
            position: absolute
            z-index: 10
            left: calc(50% - 60px)
            bottom: 0px
            background: #fff
            padding: 10px
            cursor: pointer
            width: 50px
            height: 50px
            background: white url(../img/highlights/prev.svg) center no-repeat
            background-size: 12px
            padding: 10px
            transform: translate(-50%)
        #next 
            position: absolute
            cursor: pointer
            z-index: 10
            left: calc(50% + 60px)
            bottom: 0px
            background: #fff
            padding: 10px
            width: 50px
            height: 50px
            background: white url(../img/highlights/next.svg) center no-repeat
            background-size: 12px
            padding: 10px
            transform: translate(-50%)
#gallery
    line-height: 0
    margin-top: 70px
    min-height: 30px
    margin-bottom: 50px
    img 
        width: calc(100%/3)
        display: inline-block
        margin: 0
        padding: 0
        @media (max-width: 1000px)
            width: calc(100%/2)
