*
	font-family: ff-enzo-web, sans-serif
	font-size: 15px
a
	color: $base-font-color
	&:hover
		color: $main-color
		

h1
	font-size: 2.5em
	color: $blau
	line-height: 1.2
	@media (max-width: 500px)
		font-size: 2em
	
h2
	font-size: 2em
	color: $blau
	line-height: 1.2
	@media (max-width: 500px)
		font-size: 1.7em!important
		line-height: 1.2
h3
	color: white
	line-height: 1.2
	font-size: 1.2em
	@media (max-width: 500px)
		font-size: 1.1em
h4
	font-size: 1.1em
	

h5
	color: #004F5C
	font-size: 1.5em
	font-weight: 300
	@media (max-width: 500px)
		font-size: 1.3em
strong 
	color: #5B5B5B


