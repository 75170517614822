.ticket--modal
	position: fixed
	left: 0
	top: 0
	width: 100%
	height: 100%
	z-index: 99999999999999999
	background: rgba(black, 0.4)
	padding: 1rem
	@media (min-width: 400px)
		display: flex
		align-items: center
		justify-content: center
		padding: 2rem
	&-inner
		background: white
		padding: 2rem 2rem 2rem
		position: relative
		max-width: 60rem
		overflow: auto
		max-height: 95vh

		span
			position: absolute
			right: 1rem
			line-height: 1
			top: 1rem
			font-size: 2rem
			cursor: pointer
			z-index: 1
		a
			display: block
			margin-top: 2rem
			text-align: center
		.covid__info
			text-align: center
			padding-top: 1rem
			a
				margin: 0
				display: inline