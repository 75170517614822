.header
	width: 100%
	//max-width: 1200px
	margin: 0 auto
	min-height: 450px
	max-height: 600px
	background: #ebf6fb url(../img/clauskleber.jpg) top left 55% no-repeat
	background-size: cover
	position: relative
	height: 60vw
	@media (min-width: 751px)
		height: 70vw
		background-position: top center
	.stoerer--programm
		position: absolute
		right: 1rem
		bottom: 1rem
		background: $main-color
		width: 10rem
		height: 10rem
		display: flex
		color: white
		padding: 1rem
		text-align: center
		align-items: center
		justify-content: center
		border-radius: 50%
		transform: rotate(-10deg)
		font-weight: bold
		@media (max-width: 750px)
			width: 8rem
			height: 8rem
	.sternchen
		position: absolute
		right: 1rem
		bottom: 1rem
		color: white
		display: block
		padding: 0.5rem 1rem
		background: $blau
		max-width: 250px
		text-align: center
		&:before
			content: ""
			position: absolute
			display: block
			width: 20px
			height: 20px
			top: 0
			left: 50%
			background: inherit
			transform: rotate(45deg) translate(-50%,-25%)
			@media (min-width: 750px)
				left: 20%
	.header-content
		position: absolute
		right: 0
		left: 0
		top: 50%
		transform: translateY(-50%)
		padding: 20px!important
		@media (min-width: 1200px)
			margin: 0 auto
		@media (max-width: 750px)
			width: 100%
			top: 0
			transform: translateY(0)
		width: 70%
		// padding-top: 50px
		height: auto
		display: block
		align-items: center
		// justify-content: center
		flex-wrap: wrap
		ul
			margin-top: 2rem
			@media (max-width: 750px)
				margin-top: .5rem
			li
				list-style-type: disc
				margin-left: 20px
				@media (max-width: 750px)
					margin-left: 10px
		*
			color: white
		h2
			@media (max-width: 750px)
				font-size: 2rem
		h1
			padding: 0!important
			margin-left: 0!important
			display: inline
			background: none!important
			max-width: 17ch
			transform-origin: left top
			@media (max-width: 750px)
				// transform: scale(0.9)
			@media (max-width: 400px)
				transform: scale(0.9)
			span
				font-size: inherit
				display: inline-block
				background: $blau
				line-height: 1
				padding: 1rem
				box-shadow: 0 0 1rem rgba(black, 0.2)
				&:nth-of-type(2)
					transform: translateX(0rem)
					@media (min-width: 751px)
						transform: translateX(3rem)
				&:nth-of-type(3)
					transform: translate(-1rem, -0.5rem) rotate(2deg)
					@media (min-width: 751px)
						transform: translate(2rem, -0.5rem) rotate(2deg)
				&:nth-of-type(1)
					transform: translate(-1rem, 0.5rem) rotate(-3deg) scale(0.8)
					@media (min-width: 751px)
						transform: translate(0rem, 1rem) rotate(-3deg) scale(0.8)

		@media (min-width: 751px)
			padding-top: 100px
		.aktionslogo
			width: 8rem
			height: auto
			@media (min-width: 751px)
				width: 11rem
		h1, h3
			display: inline-block
			padding: 8px 16px
		h1
			background: $blau
			color: white
			margin-left: 15px
			font-size: 4em
			z-index: 1
			// transform: rotate(-1deg)
		h3
			background: white
			color: $blau
			transform: rotate(2deg) translateY(-2px)
			font-size: 2em
			z-index: 0
			padding-top: 12px
		.btn-gelb
			padding: 10px 20px
			text-align: left
			transform: rotate(-2deg) translateY(-10%)
			margin-left: 40px
			*
				color: white
				line-height: 1.2
				letter-spacing: 0
			p
				font-size: 0.725em
				font-weight: 400
				margin-top: 2px
		@media (max-width: 960px)
			h1
				font-size: 3em
			h3
				font-size: 1.5em
		@media (max-width: 760px)
			
			h1
				font-size: 2em
			h3
				font-size: 1.2em

			

	img 
		width: 100%
		margin-bottom: -10px
		position: relative
	.stoerer
		position: absolute
		right: 5%
		top: 30%
		width: 220px
		height: 220px
		box-sizing: border-box
		padding: 10px
		text-align: center
		background: #ffbb00
		border-radius: 50%
		display: flex
		font-size: 1.4rem
		align-items: center
		color: white
		font-weight: 700
		line-height: 1.2
		@media (max-width: 950px)
			top: 20%
			width: 180px
			height: 180px
			font-size: 1.2rem
		@media (max-width: 670px)
			top: 20%
			width: 150px
			height: 150px
			font-size: 1rem
