.trendForumbg
    .trendForum
        text-align: center
        .headerTrendforum
            background: url(../img/sj_bg.jpg) no-repeat 
            background-size: cover
            width: 100%
            position: relative
            display: flex
            flex-wrap: wrap-reverse
            .headline
                align-items: center
                margin: auto
                min-width: 200px
                h1 
                    text-align: left
                    font-size: 5em
                    line-height: 1
                   
                h2 
                    color: white
                    font-size: 3em
                @media (max-width: 1200px)
                    margin-top: 100px
                    h1
                        line-height: 1em
                        font-size: 4em
                @media (max-width: 800px)
                    h1
                        font-size: 3em
                    h2 
                        font-size: 2em
                @media (max-width: 750px)
                    margin-top: 50px
                @media (max-width: 400px)
                    h1
                        font-size: 2.5em  

            img 
                width: 100%
                height: 100%
                max-width: 650px
                left: 0
                bottom: 0
                margin-top: 20px
                @media (max-width: 1200px)
                    max-width: 350px

        .zukunft
            background: $hellblau
            h2 
                padding-top: 20px
            p 
                padding: 20px 20px 0px
            
        .visionaere
            margin: 30px 0 50px
            h2 
                padding-top: 20px
            .row2
                display: flex
                justify-content: space-between
                flex-wrap: wrap
                justify-content: center
                margin-top: 30px
                .soft-row
                    margin: 5px
                    width: 23.75%
                    min-width: 250px
                    @media (max-width: 1054px)
                        width: 40%
                    @media (max-width: 534px)
                        width: 100%
                .teil1
                    min-height: 175px
                    display: flex
                    padding: 10px
                    align-items: center
                    @media (max-width: 534px)
                        padding: 15px 
                        min-height: 120px
                p
                    color: white
                    font-size: 1.3em
                    strong
                        color: white
                        font-size: 1em
                .einsO
                    background: #3B5998
                .zweiO
                    background: #CC0000
                .dreiO
                    background: #001E78
                .vierO
                    background: #DD4B39

                .teil2
                    display: flex
                    align-items: center 
                    background: $hellblau
                    width: 100%
                    padding: 10px
                    min-height: 95px
                    justify-content: center
                

                .einsU
                    p
                        color: #3B5998
                .zweiU
                    p
                        color: #CC0000
                .dreiU
                    p
                        color: #001E78
                .vierU
                    p
                        color: #DD4B39

        #ideen
            background: $hellblau
            h2 
                padding-top: 20px
            p 
                padding:  0 20px
            .row3
                display: flex
                justify-content: center
                margin: 0 10px
                flex-wrap: wrap
                p 
                    color: $blau
                    margin-bottom: 10px
                    font-size: 1.5em
                    strong
                        color: $blau
                        font-size: 1.2em
                a 
                    justify-content: space-between
                .anmelden
                    margin-bottom: 20px
                    padding: 5px
                .newsletter
                    padding: 5px
