.allesponsoren
    background: $hellblau
    text-align: center
    .hauptsponsoren2018
        background: white
    h3 
        color: $blau
        font-size: 2em
        margin: 30px
    h2 
        font-size: 3em
        padding: 30px 12px
        margin-bottom: 30px
        @media (max-width: 500px)
            font-size: 2.5em!important
        span 

            font-size: 0.7em

.hauptsponsoren
    display: grid
    grid-template-columns: repeat(4, 1fr)
    padding: 20px
    @media (max-width: 775px)
        grid-template-columns: repeat(3, 1fr)
    @media (max-width: 610px)
        grid-template-columns: repeat(2, 1fr)
    .s2 
        display: flex
        height: 70px
        a 
            top: 50%
            left: 50%
            transform: translate(-50%,-50%)
        img 
            left: 50%
            top: 50%
            transform: translate(-50%,-50%)
            max-height: 60px
        .cooperImg
            // max-height: 80px
            // width: 200px
          
.sponsoren
    padding: 40px
    display: grid
    grid-template-columns: repeat(5, 1fr)
    @media (max-width: 980px)
        grid-template-columns: repeat(4, 1fr)
    @media (max-width: 650px)
        grid-template-columns: repeat(3, 1fr)
    @media (max-width: 520px)
        grid-template-columns: repeat(2, 1fr)
    // display: flex
    // justify-content: center
    // flex-wrap: wrap
    .sponsor-single
        flex: 1 0 20%
        padding: 5px
        
        min-width: 120px
        a
            display: flex
            align-items: center
            justify-content: center
            width: 100%
            height: 100%
            img
                max-width: 100%
                max-height: 80px
                
   
    .s1
        justify-content: space-between
        display: flex
        max-width: 200px
        @media (max-width: 460px)
            max-width: 150px
        img 
            width: 80%
            left: 50%
            top: 50%
            transform: translate(-50%,-50%)


.sponsorenHeader
    background: url(../img/sj_bg.jpg) no-repeat
    background-size: cover
    width: 100%
    position: relative
    display: flex
    flex-wrap: wrap-reverse!important
    div
        margin: auto 
        max-width: 500px
        @media (max-width: 1063px)
            padding-top: 50px
        @media (max-width: 600px)
            padding: 20px
        h1 
            color: white
            line-height: 1
            @media (max-width: 560px)
                font-size: 1.7em
        span 
            color: $blau
            font-size: 2em
            @media (max-width: 1063px)
                font-size: 1.5em
        h4 
            color: white
            @media (max-width: 560px)
                font-size: 1em
    img 
        width: 80%
        height: 100%
        max-width: 550px
        left: 0
        bottom: 0
        margin-top: 20px
        @media (max-width: 1063px)
            max-width: 350px
        @media (max-width: 500px)
            width: 50%
    