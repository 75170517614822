#ticket--intro
    padding-top: 100px
    p
        max-width: 1000px
.content-idee
    padding-top: 0!important
#uploadForm
    margin: 40px 0
    background: none
    padding: 0
    #confirmation
        position: relative
        display: flex
        margin-top: 20px

    input[type="email"],input[type="text"]
        border: 1px solid lightgrey
    input[type="checkbox"]
        width: 20px
        height: 20px
        top: 0
        margin: 0
        margin-right: 10px
        // &:checked~label[for="confirm"]
        //     &:before
        //         border: 1px solid $blau
        //         content: "✔"
        //         text-align: center
        //         line-height: 14px
        //         font-size: 1.2em
        //         color: $blau
    label[for="confirm"]
        position: relative
        cursor: pointer
        display: inline-block
        // &:before
        //     content: ""
        //     width: 14px
        //     height: 14px
        //     border: 1px solid lightgrey
        //     display: block
        //     position: absolute
        //     left: -25px
        //     top: 2px
    select
        margin: 15px 0
        padding: 5px 10px
        border-radius: 4px
        border: 1px solid lightgrey
 
    input[type="file"]
        padding: 0
        left: 0
        top: 0
        display: none
    #file-label
        position: relative
        left: 0
        background: #667477
        display: inline-block
        padding: 10px 20px
        color: white
        cursor: pointer
        transition: all 300ms ease
        &:hover
            box-shadow: 0 0 10px rgba(black, 0.15)
    #filename
        display: inline-block
        margin-left: 10px
    #tel
        position: absolute
        visibility: hidden
        pointer-events: none
        left: -200vw
    button[type="submit"]
        appearance: none
        display: flex
        justify-content: center
        align-items: center
        cursor: pointer
        margin-top: 20px
        margin-bottom: 10px
        padding: 20px 25px
        background: $gelb
        width: 100%
        color: white
        border: none
        outline: none
        box-sizing: border-box
        transition: all 300ms ease
        color: white!important

        span
            text-transform: uppercase
            font-weight: 600
            letter-spacing: 1px
            color: white!important
        img
            transition: all 300ms ease
            width: 30px
            display: inline-block
            margin-right: 10px
            position: relative
            top: 0

        &:hover
            background: lighten($gelb, 3%)
            box-shadow: 1px 1px 10px rgba(black, 0.15)
            img
                top: -5px
    em
        font-weight: 600
        font-style: normal
        font-size: 0.825em
        text-align: center
        margin: 0 auto 30px
        color: grey
        display: block

#slideshow
    width: 100%
    height: 35vw
    max-height: 460px
    min-height: 200px
    background: lightgrey
    .slick-list, .slick-track
        height: 100%
        width: 100%
    .slick-dots
        position: absolute
        left: 50%
        bottom: 10px
        transform: translate(-50%)
        li
            button
                display: inline-block
                border-radius: 50%
                background: white
                height: 14px
                width: 14px
                &:before
                    content: ""
                    background: $blau
                    opacity: 0
                    width: 10px
                    height: 10px
                    left: 50%
                    top: 50%
                    transform: translate(-50%,-50%)
                    border-radius: 50%
            &.slick-active
                button
                    &:before
                        opacity: 1
    .slide
        width: 100%
        height: 100%
        &.slide1
            background: url('../img/idee_header1.jpg') top left
            background-size: cover
        &.slide2
            background: url('../img/idee_header2.jpg') center right
            background-size: cover
        &.slide3
            background: url('../img/idee_header3.jpg') left center
            background-size: cover
        .slide-content
            display: flex
            justify-content: center
            height: 100%
            width: 60%
            max-width: 500px
            padding: 30px
            flex-direction: column
            h2, h3, h4, h5
                color: white
                text-shadow: 1px 1px 4px rgba(black, 0.5)
                line-height: 1.2
            h2
                font-size: 5em
            h3
                font-size: 2em
            h4
                font-size: 2em
            h5
                font-weight: 400
                font-size: 1em
                margin-top: 20px

            @media (max-width: 600px)
                h2
                    font-size: 3em
                h3, h4
                    font-size: 1.4em
                h5
                    font-size: 0.825em
             


.centered-content
    padding: 60px 20px
    h1
        line-height: 1
        margin-bottom: 20px
        text-transform: uppercase
    p
        max-width: 600px
        margin: 0 auto
        font-size: 1.2em
.col-1
    &.padding
        min-width: 270px
        display: flex
        flex-direction: column
        justify-content: center
        padding: 6vw 5vw
        h2
            margin-bottom: 20px
            text-transform: uppercase
            line-height: 1.2
            
        p
            max-width: 500px
.image-idee    
    background: url('../img/idee.jpg') center
    background-size: cover
    // height: 100vw
    // max-height: 600px
.image-mitmachen    
    background: url('../img/mitmachen.jpg') bottom center
    background-size: cover
    // height: 100vw
    // max-height: 800px
.image-opti    
    background: url('../img/dohr.jpg') top center
    background-size: cover
    //height: 100vw
    //max-height: 800px
.image-upload    
    background: url('../img/upload.jpg') center
    background-size: cover
    //max-height: 800px
.image-jury    
    background: url('../img/jury.jpg') center
    background-size: cover
    // height: 100vw
    // max-height: 400px

.sr-idee
    align-items: none
    #opti_thumb
        width: 100%
        height: auto
        display: block
        margin: 20px 0
    .bubble
        display: block
        padding: 20px
        background: $blau
        margin-top: 20px
        margin-bottom: 20px
        position: relative
        &:before
            content: ""
            position: absolute
            width: 20px
            height: 20px
            background: inherit
            left: 0
            top: 50%
            transform: translate(-50%,-50%) rotate(45deg)
        p, b
            color: white
    @media (max-width: 740px)
        display: block
        div[class*='image-']
            height: 40vw
            min-height: 300px
            &.image-mitmachen
                background-position: bottom 10% center
            &.image-opti
                background-position: top 10% center
        .image-upload
            order: 1
        .bubble
            margin-top: 0
            &:before
                left: 50%
                top: 0
.order2
    order: 2


#modal
    position: fixed
    padding: 40px 40px
    background: #fff
    top: 50%
    left: 50%
    transform: translate(-50%,-50%)
    z-index: 99999999999999
    min-width: 300px
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column
    box-shadow: 0 0 10px rgba(black,0.2), 0 0 10px 5000px rgba(black, 0.8)
    border-radius: 4px
    a
        background: #6bd06b
        text-align: center
        color: white
        margin: 20px 0 0
        padding: 10px 15px
        display: block
        width: 100%
.modal
    display: none
    position: fixed
    padding: 40px 40px
    background: #fff
    top: 50%
    left: 50%
    transform: translate(-50%,-50%)
    z-index: 99999999999999
    width: 90vw
    height: 90vh
    overflow: auto
    box-shadow: 0 0 10px rgba(black,0.2), 0 0 10px 5000px rgba(black, 0.8)
    border-radius: 4px
    h2
        margin-bottom: 20px
    p
        margin-bottom: 30px
    a
        position: relative
        margin-bottom: 40px
.idee--iframe
    position: relative
    img
        max-width: 100%
        visibility: hidden
    iframe
        position: absolute
        left: 0
        top: 0
        width: 100%
        height: 100%