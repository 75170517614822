.container
    max-width: 1200px
    margin: 0 auto
.centered-content
    padding: 30px
    text-align: center

.row
    padding: 30px
    display: block
    max-width: 800px
    margin: 0 auto
.soft-row
    display: flex
    flex-wrap: wrap
.col-1
    flex: 1 0 0 
.col-2
    flex: 2 0 0 
.col-3
    flex: 3 0 0 
.col-4
    flex: 4 0 0 
.col-5
    flex: 5 0 0 
.col-6
    flex: 6 0 0 

hr
    margin: 20px 0

.btn-gelb
    background: #FFBB00
    text-decoration: none
    color: white
    padding: 5px 25px
    font-size: 20px
    font-weight: 600
    letter-spacing: 1px
    display: inline-block
    position: relative
    bottom: 0px
    transition: all 300ms ease
    &:hover 
        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.30)
        color: white
    @media (max-width: 400px)
        font-size: 15px
.error
    background: #e16f4a
    text-align: center
    color: white
    margin: 10px 0
    padding: 10px 15px
    display: block


.lightblue
    background: #DFEBFC
.fullwidth
    width: 100%
.padding
    padding: 30px


.info--box
    margin: 0 auto 2rem
    padding: 1rem
    background: white
    text-align: center
    border: 2px solid $blau
    width: 100%
    max-width: 40rem
    *
        text-align: center
        display: block
        // color: $blau
.info--box-blue
    margin: 0 auto 2rem
    padding: 2rem
    background: $hellblau
    text-align: center
    border: 1px solid $blau
    width: 100%
    *
        text-align: center
    h4
        color: $blau
        font-size: 1.2rem
        margin-bottom: 1rem
        display: block
